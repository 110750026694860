import React, {
  Suspense,
  useEffect,
  useState,
  Fragment,
  createContext,
} from "react";

import {
  Routes,
  Route,
  BrowserRouter as Router,
  useLocation,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import "./i18n";
import { AnimatePresence, createBox } from "framer-motion";
import { HelmetProvider } from "react-helmet-async";

import { Navbar } from "./components";
import ScroolToTop from "./components/ScroolToTop";

import AnimatedRoutes from "./Pages/AnimatedRoutes";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";

export const langContext = React.createContext("fr");

function App() {
  const TRACKING_ID = "UA-263323681-1";
  useEffect(()=>{
    ReactGA.initialize(TRACKING_ID);
  } ,[]);



  const lngs = ["en", "fr"];
  const [value, setValue] = useState("Fr");
  // checking the if language is valid one
  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  const handleValue = (event) => {
    setValue(event.target.textContent);
    console.log(i18next.changeLanguage(event.target.textContent));
  };

  return (
    <Suspense fallback={null}>
      <HelmetProvider>
        <div className="App">
          <AnimatePresence>
            <BrowserRouter>
              <Navbar value={value} lngs={lngs} handleValue={handleValue} />
              <ScroolToTop>
                <langContext.Provider value={value.toLowerCase()}>
                  <AnimatedRoutes />
                </langContext.Provider>
              </ScroolToTop>
            </BrowserRouter>
          </AnimatePresence>
        </div>
      </HelmetProvider>
    </Suspense>
  );
}

export default App;
