import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Banner = () => {
    const { t } = useTranslation('common');
    return (
        <Fragment>
            <div className="px-4 sm:px-6 py-[90px] md:py-[90px] lg:py-[126px] bg-blue-100">
                <div className="content-wrapper flex items-center justify-between flex-col md:flex-row">
                    <div className="section-text lg:w-[614px]">
                        <h2 className="text-2xl md:text-[35px] xl:text-[48px] font-bold tracking-tight mb-6 text-center md:text-left text-[#184059] leading-[30px] md:leading-[53px]">
                            {t('bannerText')}
                        </h2>
                        <p className="text-black text-center md:text-left md:mt-[31px] text-semibold mb-5 md:mb-0 md:w-[400px] lg:mt-[51px] lg:w-[518px]">
                            {t('bannerDesc')}
                        </p>
                    </div>
                    <div className="md:flex md:justify-between md:items-center mt-4 md:mt-0 text-center lg:text-base xl:text-lg">
                        <Link to={'contact'} className="py-[15px] px-[40px] block text-[14px] lg:px-[60px] xl:px-[71px] lg:py-[32px] md:px-[30px] md:py-[25px] md:h-12 text-white bg-blue-500 hover:bg-blue-400 border-2 border-transparent hover:border-2 hover:border-blue-300 hover:text-white transition duration-500 md:flex md:items-center md:justify-center">{t('contactezNous').toUpperCase()}</Link>
                    </div>
        
                </div>
            </div>
        </Fragment>
    )
}

export default Banner