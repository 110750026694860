import React, { Fragment } from 'react';

import { data } from '../../utils/constants';
import {ProjectCard} from '../../components'
import { useTranslation } from "react-i18next";

const SimilarCategory = ({similarProjet}) => {
  const {t} = useTranslation("projets") ;
  return (
    <Fragment>
        <section className="similar-projet py-[90px] lg:py-[115px]">
            <div className="content-wrapper">
                <h2 className="headline-border">{t("relatedProject")}</h2>

                <div className="
                card-projet-similar grid 
                grid-cols-1 gap-y-[20px] 
                lg:grid-cols-3 lg:gap-x-[20px] 
                md:grid-cols-2 md:gap-x-5 justify-items-center
                lg:max-w-[950px] lg:mx-auto
                lg:pt-[204px] pt-[90px]">
                    {
                       similarProjet.map((projet) => {
                        return(
                          <ProjectCard 
                          img={projet.imgs[0]}
                          key={projet.id}
                          projAlt={projet.name}
                          projDesc={projet.description}
                          projId={projet.id}
                          projTitle={projet.name}
                          />
                        )
                       }) 
                    }
                </div>
            </div>
        </section>
    </Fragment>
  )
}

export default SimilarCategory