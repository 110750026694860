import React, { useContext, Fragment } from "react";
import { ProjectContext, DomaineContext } from "../projets/Projet";
const ProjetStats = ({ project }) => {
  const projects = useContext(ProjectContext);
  const domaines = useContext(DomaineContext);

  return (
    <Fragment>
      <div
        className="
        content-wrapper 
        py-[32px] lg:py-[17px] 
        bg-white shadow-lg
        -translate-y-[80px] lg:-translate-y-[50px]
        mx-[12px] lg:mx-auto
        "
      >
        <div
          className="
            stats-container flex 
            items-start justify-between 
            flex-col lg:flex-row
            gap-y-9 px-[14px] 
            min-h-full h-full
            lg:px-[108px]"
        >
          <div className="stats w-full lg:w-[219px]" >
            <h6 className="title">client</h6>
            <span className="det lg:mt-[12px]">{project.client}</span>
          </div>
          <div className="stats w-full lg:w-[219px]" >
            <h6 className="title">Emplacement</h6>
            <span className="det lg:mt-[12px]">{`${project.ville} - ${project.pays}`}</span>
          </div>
          <div className="stats w-full lg:w-[219px]" >
            <h6 className="title">Date</h6>
            <span className="det lg:mt-[12px]">{project.date}</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProjetStats;
