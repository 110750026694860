import React, {useEffect , Fragment } from 'react';
import ReactGA from  'react-ga' ;


import { useTranslation } from 'react-i18next';

import { Header } from '../../components'
import AnimatePage from '../../components/UI/AnimatePage';
import { image } from '../../utils/constants'
import ContactForm from './ContactForm';
import SEO from '../../SEO';
import Map from './Map';

const ContactezNous = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  const { t } = useTranslation("contact")

  return (
    <Fragment>
      <SEO
      title="contactez nous"
      description="Contactez NOVE pour vos solution en genie civil"
      type="website"
      img="https://img.freepik.com/premium-photo/engineer-black-women-worker-professional-woman-afican-mechanical-maintenance-work-factory_43300-3538.jpg?w=360"
      />

        <Header
        img={image.imgContact}
        title={t('contactHeadline')}
        />
        <ContactForm />
        <Map />

    </Fragment>
  )
}

export default ContactezNous
