import logoNove from "../../assets/imgs/logo-nove.png";
import logoFooter from "../../assets/imgs/logo-footer.png";
import logoCRM from "../../assets/imgs/logo-partenaire/logo-ccm.png";
import logoCrid from "../../assets/imgs/logo-partenaire/logo-crid.png";
import logoCrebat from "../../assets/imgs/logo-partenaire/logo-crebat.jpg";
import logoAspac from "../../assets/imgs/logo-partenaire/logo-aspac.jpg";
import logoUNDPS from "../../assets/imgs/logo-partenaire/logo-undps.jpg";
import cdim from "../../assets/imgs/logo-partenaire/cdim.png";
import amtis from "../../assets/imgs/logo-partenaire/amtis.png";
import nfa from "../../assets/imgs/logo-partenaire/nfa.png";
import unesco from "../../assets/imgs/logo-partenaire/unesco.png";


/* images de l'equipe equipe */
import constant from "../../assets/imgs/img-equipe/constant.jpg";
import constantpp from "../../assets/imgs/img-equipe/constantpp.jpg";
import camille from "../../assets/imgs/img-equipe/camille.jpg";
import mefoug from "../../assets/imgs/img-equipe/mefoug.jpg";
import yonta from "../../assets/imgs/img-equipe/yonta.jpg";
import hypo from "../../assets/imgs/img-equipe/hippolyte.jpg";
import murielle from "../../assets/imgs/img-equipe/murielle.jpg";
import pngJohn from "../../assets/imgs/png-john.png";
import pngMary from "../../assets/imgs/png-mary.png";
import imgTestimonial3 from '../../assets/imgs/testimonial-3.jpg';


import imgService1 from "../../assets/imgs/img-service-1.png";
import imgService2 from "../../assets/imgs/img-service-2.jpeg";
import imgService3 from "../../assets/imgs/img-service-3.png";
import imgService4 from "../../assets/imgs/img-service-4.png";
import imgService5 from "../../assets/imgs/img-service-5.png";
import imgService6 from "../../assets/imgs/img-service-6.png";
import imgService7 from "../../assets/imgs/img-service-7.png";
import imgService8 from "../../assets/imgs/img-service-8.png";
import imgService9 from "../../assets/imgs/img-service-9.png";

import introImage1 from "../../assets/imgs/img-intro1.jpg";
import introImage2 from "../../assets/imgs/img-intro2.jpg";
import imgEtudes from "../../assets/imgs/img-etudes.jpg";
import johnDoe from "../../assets/imgs/john-doe.jpg";
import imgTestimonial from "../../assets/imgs/img-testimonial.png";
import imgContact from "../../assets/imgs/img-contact.jpg";
import imgAbout from "../../assets/imgs/img-about.jpg";
import imgHands from "../../assets/imgs/img-hands.jpg";
import imgService from "../../assets/imgs/img-service.jpg";
import imgProjet from "../../assets/imgs/img-project.jpg";
// Project Images Headlines
import imgBatiment1 from "../../assets/imgs/img-batiment/img-r+1/14.jpg";
// Project images for project 1
import imgBatiment1a from "../../assets/imgs/img-batiment/img-r+1/10.jpg";
import imgBatiment1b from "../../assets/imgs/img-batiment/img-r+1/11.jpg";
import imgBatiment1c from "../../assets/imgs/img-batiment/img-r+1/12.jpg";
import imgBatiment1d from "../../assets/imgs/img-batiment/img-r+1/13.jpg";
import imgBatiment1e from "../../assets/imgs/img-batiment/img-r+1/14.jpg";

import imgBatiment2 from "../../assets/imgs/img-batiment/img-rdc+2/9.jpg";
// Project Images for Project 2
import imgBatiment2a from "../../assets/imgs/img-batiment/img-rdc+2/1.jpg";
import imgBatiment2b from "../../assets/imgs/img-batiment/img-rdc+2/2.jpg";
import imgBatiment2c from "../../assets/imgs/img-batiment/img-rdc+2/4.jpg";
import imgBatiment2d from "../../assets/imgs/img-batiment/img-rdc+2/1.jpg";

import imgBatiment3 from "../../assets/imgs/img-batiment/img-tradex/22.jpg";
// Project Images for project 3
import imgBatiment3a from "../../assets/imgs/img-batiment/img-tradex/21.jpg";
import imgBatiment3b from "../../assets/imgs/img-batiment/img-tradex/23.jpg";
import imgBatiment3c from "../../assets/imgs/img-batiment/img-tradex/24.jpg";
import imgBatiment3d from "../../assets/imgs/img-batiment/img-tradex/25.jpg";
import imgBatiment4 from "../../assets/imgs/img-batiment/img-crid/26.jpg";
import imgBatiment5 from "../../assets/imgs/img-batiment/img-particulier/27.jpeg";
// Projet Images for Infrastructure
import imgInfra1 from "../../assets/imgs/img-infra-1.jpg";
import imgInfra2 from "../../assets/imgs/img-infra-2.jpg";
import imgInfra3 from "../../assets/imgs/img-infra-3.jpg";
import imgInfra4 from "../../assets/imgs/img-infra-4.jpg";
import imgInfra5 from "../../assets/imgs/img-infra-5.jpg";
import imgInfra6 from "../../assets/imgs/img-infra-6.jpg";
// Project Image for Energy
// Images for the first project
import imgEnergy1 from "../../assets/imgs/img-energy/32.jpeg";
import imgEnergy1a from "../../assets/imgs/img-energy/33.jpeg";
import imgEnergy1b from "../../assets/imgs/img-energy/36.jpg";
import imgEnergy1c from "../../assets/imgs/img-energy/37.jpg";
import imgEnergy1d from "../../assets/imgs/img-energy/38.jpg";
// Images for the second project
import imgEnergy2 from "../../assets/imgs/img-energy/28.jpeg";
import imgEnergy2a from "../../assets/imgs/img-energy/28-2.jpg";
import imgEnergy2b from "../../assets/imgs/img-energy/28-3.jpg";
import imgEnergy2c from "../../assets/imgs/img-energy/28-4.jpg";
import imgEnergy2d from "../../assets/imgs/img-energy/28-5.jpg";
import imgEnergy2e from "../../assets/imgs/img-energy/28-6.jpg";
// Images for the third project
import imgEnergy3 from "../../assets/imgs/img-energy/30.jpeg";
import imgEnergy3a from "../../assets/imgs/img-energy/31.jpeg";
// Images for the fouth projects
import imgProj1 from "../../assets/imgs/img-proj-1.jpg";
import imgProj2 from "../../assets/imgs/img-proj-2.jpg";
import imgProjDetails from "../../assets/imgs/img-proj-details.jpg";
import imgShowcase from "../../assets/imgs/show-case.jpg";

// Images grouped by "secteur activite"

import iconEtudes from "../../assets/imgs/icon-etude.svg";
import iconQuotes from "../../assets/imgs/icon-quote.svg";
import arrowLeft from "../../assets/imgs/arrow-left.svg";
import arrowRight from "../../assets/imgs/arrow-right.svg";
import iconPhone from "../../assets/imgs/icon-phone.svg";
import iconEmail from "../../assets/imgs/icon-email.svg";
import iconLocation from "../../assets/imgs/icon-location.svg";
import iconFlag from "../../assets/imgs/icon-flag.svg";
import iconManagement from "../../assets/imgs/icon-management.svg";

/** image for service */
import etudeStructurelle from "../../assets/imgs/services/etude_structurelle.jpg";
import managementDeprojet from "../../assets/imgs/services/management_de_projet.jpg";
import auditEtConseil from "../../assets/imgs/services/audit_et_conseil.jpg";

/* image domaines */

import batiment from "../../assets/imgs/domaines/building.jpg" ;
import infrastructure from  "../../assets/imgs/domaines/infra-railway.jpg" ;
import energie from  "../../assets/imgs/domaines/energie.jpg" ;
import ouvrageDart from  "../../assets/imgs/domaines/ouvrage-art-viaducs-structural-engineering-work.jpg" ;

// home - testimonials
import testimonial_man from "../../assets/imgs/testimonials/testimonial_man.jpg";
import testimonial_woman from "../../assets/imgs/testimonials/testimonial_woman.jpg";

export default {
  logoNove,
  logoFooter,
  logoCRM,
  logoCrebat,
  logoCrid,
  logoUNDPS,
  logoAspac,
  cdim,
  amtis,
  nfa,
  unesco,
  pngJohn,
  pngMary,
  imgService1,
  imgService2,
  imgService3,
  imgService4,
  imgService5,
  imgService6,
  imgService7,
  imgService8,
  imgService9,

  introImage1,
  introImage2,
  imgEtudes,
  johnDoe,
  imgTestimonial,
  imgTestimonial3,
  imgContact,
  imgAbout,
  imgHands,
  imgService,
  imgProjet,
  imgBatiment1,
  imgBatiment1a,
  imgBatiment1b,
  imgBatiment1c,
  imgBatiment1d,
  imgBatiment1e,
  imgBatiment2,
  imgBatiment2a,
  imgBatiment2b,
  imgBatiment2c,
  imgBatiment2d,
  imgBatiment3,
  imgBatiment3a,
  imgBatiment3b,
  imgBatiment3c,
  imgBatiment3d,
  imgBatiment4,
  imgBatiment5,
  imgInfra1,
  imgInfra2,
  imgInfra3,
  imgInfra4,
  imgInfra5,
  imgInfra6,
  imgEnergy1,
  imgEnergy1a,
  imgEnergy1b,
  imgEnergy1c,
  imgEnergy1d,
  imgEnergy1d,
  imgEnergy2,
  imgEnergy2a,
  imgEnergy2b,
  imgEnergy2c,
  imgEnergy2d,
  imgEnergy2e,
  imgEnergy3,
  imgEnergy3a,
  imgProj1,
  imgProj2,
  imgProjDetails,
  imgShowcase,

  iconEtudes,
  iconQuotes,
  arrowLeft,
  arrowRight,
  iconEmail,
  iconLocation,
  iconPhone,
  iconFlag,
  iconManagement,
  /* image for services */
  etudeStructurelle,
  managementDeprojet,
  auditEtConseil,
  constant ,
  constantpp,
  camille ,
  yonta ,
  mefoug ,
  hypo,
  murielle,
  batiment,
  infrastructure,
  ouvrageDart,
  energie,
  testimonial_man,
  testimonial_woman
};
