import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

const LinkItem = ({ item, path, isOpen }) => {
  return (
    <Fragment>
      <li>
        <NavLink
          
          to={path}
          className={({ isActive }) =>
            isActive
          ? "link-item active font-bold"
              : " link-item hover:text-blue-500"
          }
        >
          {item}
        </NavLink>
      </li>
    </Fragment>
  );
};

export default LinkItem;
