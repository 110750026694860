import React, {Fragment} from 'react';

import { NavLink } from 'react-router-dom';

const MobileLink = ( {item, path, setActive, hasActive} ) => {

  const handleHasActive = (e) => {

    if (hasActive === true) {
      setActive(false);
    } else (setActive(true))
  }
  return (
    <Fragment>
        <li className="">
            <NavLink
            end
            to={path}
            onClick={(e)=>{handleHasActive()}}
            className={({isActive}) => isActive ? "link-item active" : "link-item"}>{item}</NavLink>
        </li>
    </Fragment>
  )
}

export default MobileLink
