import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CallToAction = () => {
  const { t } = useTranslation('common');

  return (
    <Fragment>
        <Link to={'/contact'} href={"#"} className="py-[15px] px-[40px] lg:px-[20px] xl:px-[30px] lg:py-[24px] md:px-[30px] md:py-[25px] md:h-12 text-white bg-blue-500 hover:bg-white hover:text-blue-500 border-2 border-transparent hover:border-2 hover:border-blue-500 transition duration-500 md:flex md:items-center md:justify-center">{t('contactezNous')}</Link>
    </Fragment>
  )
}

export default CallToAction