import React, { Fragment } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

import { data, image } from "../../utils/constants";

import partenaires from "../../utils/constants/partenaireData";

const Patenaire = () => {
  const { t } = useTranslation("home");

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    fade: true,
  
  };

  const imgs = [
    image.logoCRM,
    image.logoCrebat,
    image.logoAspac,
    image.logoCrid,
    image.logoUNDPS,
  ];
  const partenairesRender = partenaires.partenaires.map((partenaire) => {
    return (
      <li key={partenaire.href} className="hover:scale-110 transition-all">
        <a
          href={partenaire.href}
          target="_blank"
          className="w-[180px] lg:w-[140px] xl:w-[180px] h-[180px] block"
        >
          <img
            src={partenaire.scr}
            alt={partenaire.alt}
            className="w-full h-full object-contain"
          />
        </a>
      </li>
    );
  });


  return (
    <Fragment>
      <section className="patenaire bg-blue-900 py-[90px] lg:py-[115px]">
        <div className="content-wrapper">
          <h2
            className="headline-border"
            style={{ color: "#ffffff", borderLeftColor: "#FFFFFF" }}
          >
            {t("patenaire")}
          </h2>

          <div className="logo-section mt-[80px] md:mt-[106px]">
            <ul className="logo-container hidden md:flex md:flex-wrap md:gap-y-5 lg:flex-wap md:gap-x-8 lg:gap-x-4 xl:gap-x-8 md:items-center md:justify-between ">
              {partenairesRender}
            </ul>

            <div className="imgslider md:hidden">
              
              {/* const {arrrows} = ...settings */}
              <Slider {...settings}>
                {partenaires.partenaires.map((item) => (
                  <div key={item} className="">
                    <a href={item.href}>
                      <img
                        src={item.scr}
                        alt={item.alt}
                        className=" w-full h-[200px] object-contain"
                      />
                    </a>
                  </div>
                ))}
              </Slider>

           
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Patenaire;
