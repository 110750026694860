import React, { Fragment, useLayoutEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const Header = ({ title, img }) => {
    const { t } = useTranslation("common");
    const ref = useRef(null);
    const [height, setHeight] = useState(0);

    useLayoutEffect(() => {
        setHeight(ref.current.clientHeight);
    }, []);
  return (
    <Fragment>
        <div ref={ref} className="relative overflow-hidden overlay-img bg-no-repeat bg-cover h-screen md:h-[560px] lg:h-[657px] px-4 sm:p-6 lg:py-[181px]" style={{backgroundImage: `url(${img})`}}>
            <div className="absolute overlay top-0 right-0 bottom-0 left-0 px-2 w-full h-full overflow-hidden bg-fixed flex items-center lg:block">
                <div className="content-wrapper flex justify-start items-center h-[657px] px-4">
                    <div className="text-left text-white">
                        <p className="mb-5 lg:mb-10 text-blue-300 lg:text-2xl pl-3 border-l-4 border-l-blue-400">{t('headerNav')}</p>
                        <h1 className="text-[35px] md:text-[50px] lg:text-[60px] font-bold tracking-tight md:leading-[63px] lg:leading-[73px] lg:w-[950px] text-white">
                            {title}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
  )
}

export default Header