import React, { Fragment, useContext, useEffect, useLayoutEffect, useState } from "react";
import { ProjectContext  , DomaineContext } from "./Projet";


import { MdOutlineArrowRightAlt } from "react-icons/md";
import { useTranslation } from "react-i18next";

import { data } from "../../utils/constants";
import ProjetBlock from "./ProjetBlock";
import ProjetNav from "./ProjetNav";

const ProjetSectionl = () => {
  const [viewMore, setViewMore] = useState(true);
  const [currentViewIndex , setCurrentViewIndex] =useState(0) ;

  const { t } = useTranslation("projets");

  const handleViewMore = () => {
    setViewMore((viewMore) => !viewMore);
  };




  useLayoutEffect(()=>{
    if (viewMore) {
      setCurrentViewIndex(2) ;
    } else {
      setCurrentViewIndex(4) ;
    }
  
  }) ;
  
  const projects = useContext(ProjectContext) ;
  const domaines = useContext(DomaineContext) ;
  
  return (
    <Fragment>
      <section className="proj-section py-[90px] lg:py-[115px]">
        <div className="sub-proj-container flex justify-center">
          <ProjetNav />
          <div className="wrapper">
            {domaines.map(
              (domaine, index) =>
                domaine.id <= currentViewIndex && (
                  <ProjetBlock
                    key={domaine.id}
                    projTitle={domaine.title}
                    linkId={domaine.link}
                    index={index}
                    projectObject="projectObject"
                  />
               
                )
            )}

            <div
              className={`${
                viewMore
                  ? "btn-container flex justify-center mt-[80px]"
                  : "btn-container justify-center mt-[80px] hidden"
              }`}
            >
              <button
                onClick={handleViewMore}
                className="btn-voir flex gap-x-8 justify-center font-medium hover:bg-blue-500 hover:text-white items-center px-5 py-[15px] border-2 border-blue-500 lg:ml-[45px] lg:w-[237px] text-blue-500 uppercase transition-all"
              >
                {t(`btnVoirPlus`)}{" "}
                <MdOutlineArrowRightAlt className="left-icon text-blue-500" />{" "}
              </button>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ProjetSectionl;
