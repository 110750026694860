import React, { Fragment, useEffect } from 'react';

import { MdArrowRightAlt } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



import { ServiceCard } from '../../components';

const NosServices = () => {

    const { t } = useTranslation("home");

  return (
    <Fragment>
        <section className="nos-service py-[90px] md:py-[96px] lg:py-[115px]">
            <div className="content-wrapper">
                <h2 className="headline-border">{t('homeServices')}</h2>

                <div className="row-service md:flex lg:grid lg:grid-cols-2 lg:gap-x-[90px] mt-[90px] md:py-[90px] lg:mt-[90px]">
                    <div className="col-2-service-text md:w-2/4 lg:w-full">
                        <h5 className='font-bold md:text-[24px]'>{t('serviceDesc')}</h5>
                        <ul className="services mt-[30px] md:mt-[53px] space-y-8 pb-12 md:pb-0">
                            <li className='text-black-300 cursor-pointer font-[400] transition-all hover:pl-4 hover:text-blue-600 hover:border-l-4 border-blue-600'>
                                <Link to={"service/etudes-structures-dexecusion"}>{t('serviceList.0.name')}</Link> </li>
                            <li className='text-black-300 cursor-pointer font-[400] transition-all hover:pl-4 hover:text-blue-600 hover:border-l-4 border-blue-600'>
                                <Link to={"service/management-de-projet"}>{t('serviceList.1.name')}</Link> </li>
                            <li className='text-black-300 cursor-pointer font-[400] transition-all hover:pl-4 hover:text-blue-600 hover:border-l-4 border-blue-600'>
                                <Link to={"service/audit-et-conseils"}>{t('serviceList.2.name')}</Link> </li>
                        </ul>

                        <>
                            <Link to={"service/etudes-structures-dexecusion"} className="
                            py-[15px] flex 
                            justify-between items-center 
                            px-[20px] lg:px-[20px] 
                            xl:px-[50px] 
                            service-icon lg:py-[12px] 
                            md:px-[30px] md:py-[25px] 
                            text-blue-500 h-12 
                            lg:h-[60px] 
                            hover:bg-blue-500 hover:text-white 
                            border-2 border-blue-500 
                            hover:border-2 hover:border-transparent 
                            gap-x-6 transition 
                            duration-500 md:flex 
                            md:items-center lg:w-[50%]
                            md:justify-center w-[200px] 
                            md:w-[70%] mt-[41px]"><span>{t('serviceCTA')}</span><MdArrowRightAlt className="icon-left"/></Link>
                        </>
                    </div>
                    <div className="col-2-service-img mt-12 md:mt-12 lg:mt-0 md:w-2/4 lg:w-full ">
                        <ServiceCard />
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
  )
}

export default NosServices