import React, { Fragment, useEffect } from 'react';

import Aos from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

import { data, image } from '../../utils/constants';
import projectData from '../../utils/constants/projectData';
import { Link } from 'react-router-dom';
import { MdOutlineArrowRightAlt } from 'react-icons/md';
import ViewMoreBtn from '../../components/UI/ViewMoreBtn';

const ProjetSection = (  ) => {

    const { t } = useTranslation("home")
    useEffect(() => {
        Aos.init({once: true});
    })
  return (
    <Fragment>
        <section className="proj-section py-[90px] lg:py-[115px] bg-[#FDFDFD]">
            
            <div className="content-wrapper relative">
            <span className="big-big-text hidden lg:block absolute 
            left-0 font-bold  
            lg:text-[80px] lg:top-[-30px] 
            xl:top-[-120px] xl:text-[200px]
            xl:left-[-152px]
            lg:left-[-60px] 2xl:text-[240px] 
            2xl:left-[-194px] 2xl:-top-[150px]">{t('homeProjetBigHeadLine')}</span>
                <h2 className="heading-big-text text-[24px] font-bold text-blue-900 mb-[40px] headline-border relative z-10">{t('homeProjetHeading')}</h2>


                <div className="projet-section-wrapper md:mt-[200px] 2xl:mt-[250px]">
                    {
                        projectData.favoritesProjects.map((projet, index) => {
                            
                            return (
                                <Fragment>
                                    <div className="projet-container md:grid md:grid-cols-2 md:gap-x-[70px] md:place-items-center  last-of-type:mt-[100px]">
                                        <div 
                                        data-aos="zoom-in"
                                        data-aos-delay="500"
                                        className="projet-image relative md:h-[442px]">
                                            <img src={projet.imgs[0]} alt="img-proj" className="h-full w-full md:h-[442px]" />
                                            <span className="proj-category absolute bottom-[50px] bg-blue-700 text-white text-center leading-[15px]">{projet.secteurDactivite}</span>
                                        </div>
                                        <div className="projet-desc mt-[80px] md:mt-[0px] relative">
                                            <span className="projet-id text-[180px] md:text-[250px] font-bold absolute -top-[110px] md:-top-[170px] lg:text-[300px] text-[#C2DFF2] opacity-50">{index + 1}</span>
                                            <div className="proj-desc-text relative z-10 lg:ml-[45px] xl:w-[406px]">
                                                <span className="uppercase text-[#BABABA]">{projet.secteurDactivite}</span>
                                                <h3 className="proj-heading text-blue-900 font-bold text-[32px]">{projet.name}</h3>
                                                <p className="proj-desc my-5 pr-[20px] md:pr-[20px]">{projet.description}</p>
                                            </div>
                                        <ViewMoreBtn projetId={parseInt(projet.id)}/>
                                        </div>
                                    </div>
                                </Fragment>
                            )
                       })
                    }
                </div>
            </div>
        </section>
    </Fragment>
  )
}

export default ProjetSection