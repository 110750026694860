import React, { Fragment, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Link } from 'react-scroll';
import { data } from '../../utils/constants'

const   ServiceNav = ({ navList, linkName }) => {
    
    const { t } = useTranslation("service");
  return (
    <Fragment>
        <div className="nav-service sticky top-[120px] left-[80px] lg:w-[193px] lg:hidden lg:h-[263px] hidden xl:hidden 2xl:block">
            <div className="service-link flex items-center relative">
                <ul className="order-2">
                    {
                        navList.map((item, index) => (
                            <li key={item.id} className="text-[12px] slider-nav">
                                <Link 
                                to={`${item.link.slice(0)}`} 
                                activeClass="active"
                                spy={true} 
                                smooth={true} 
                                offset={-50}
                                isDynamic={true}
                                duration={-150} 
                                delay={500}
                                className="cursor-pointer block py-2 mt-2 pl-[33px]"
                                >{
                                    t(`serviceNavLinks.${linkName}.${index}`)
                                }
                                </Link>
                            </li>
                        ))
                    }
                </ul>
                <span className="line-slider block w-[2px] h-[200px] bg-blue-100 "></span>
            </div>
        </div>
    </Fragment>
  )
}

export default ServiceNav