import React, { Fragment, useEffect } from 'react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';



import { TeamCard } from '../../components';
import { data } from '../../utils/constants';

const Equipe = ({ id }) => {
    const { t } = useTranslation("about-us");
    const settings = {
        infinite: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 2000,
    }
  return (
    <Fragment>
        <section className="equipe py-[90px] md:py-[150px] lg:py-[190px]">
            <div className="content-wrapper">
                <h2 className="headline-border">{t('aboutTestimonialHeading')}</h2>

                <div 
                className="card-wrapper-team md:gap-x-4 md:gap-y-8 lg:gap-y-10 xl:gap-y-[80px] lg:gap-x-10 xl:gap-x-[0px] mt-[90px] md:mt-[150px] hidden">
                    {
                        data.teamMembers.map((member, index) => (
                            <TeamCard 
                            name={member.name}
                            img={member.img}
                            content={t(`aboutTestimonialObject.${index}.text`)}
                            title={t(`aboutTestimonialObject.${index}.title`)}
                            subTitle={t(`aboutTestimonialObject.${index}.titleDesc`)}
                            key={member.id}
                            id={member.id}
                            /> 
                        ))
                    }
                </div>
            </div>

            <div className='imgslider md:hidden px-8 mt-[90px]'>
                    <Slider {...settings}>
                    {
                        data.teamMembers.map((member, index) => (
                        <TeamCard 
                            name={member.name}
                            img={member.img}
                            content={t(`aboutTestimonialObject.${index}.text`)}
                            title={t(`aboutTestimonialObject.${index}.title`)}
                            subTitle={t(`aboutTestimonialObject.${index}.titleDesc`)}
                            key={member.id}
                        /> 
                        ))
                    }
                    </Slider>
            </div>
        </section>
    </Fragment>
  )
}

export default Equipe