import React, { Fragment, useEffect } from "react";

import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

import { image } from "../../utils/constants";

const ServiceCard = () => {
  const { t } = useTranslation("home");
  useEffect(() => {
    Aos.init({ once: true });
  }, []);

  return (
    <Fragment>
      <div
        className="service-card border-r-4 lg:border-r-[10px] border-blue-400 h-[390px] lg:h-[490px] lg:w-[418px] xl:h-[590px] xl:w-[518px] relative"
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="500"
        data-aos-delay="300"
      >
        <img
          src={image.imgEtudes}
          alt="Etudes Structurelles"
          className="w-full h-full object-cover"
        />
        <div className="service-desc w-full sm:w-[80%] md:w-[311px] lg:h-[208px] lg:w-[411px] px-[15px] lg:px-[32px] bg-blue-900 absolute bottom-0 md:bottom-[61px] py-8 sm:px-[20px] md:py-[52px]">
          <span className="icon-etude p-2 md:p-4 rounded-full bg-blue-100 block w-[40px] md:w-[60px] absolute -top-5 md:-top-7">
            <img
              src={image.iconEtudes}
              alt="icon etudes structurelles"
              className="w-[40px] md:w-[100px]"
            />
          </span>
          <div className="detail">
            <h4 className="text-white font-bold capitalize text-[16px] lg:text-[26px]">
              {t("serviceDetailHead")}
            </h4>
            <p className="text-[12px] text-black-50 mt-2 md:mt-[12px] sm:pr-[150px] md:pr-[20px]">
              {t("serviceDetailDesc")}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ServiceCard;
