import i18n from "i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";


i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
        },
        fallbackLng: "fr",
        lng: "fr",
        // disabled in production @
        debug: false,
        // namespace comes in if you want to chunk a huge document to bit size
        //translations into smaller pieces and load them on demand
        ns: [
            "common", 
            "home",
            "about-us",
            "contact",
            "domaines",
            "projets",
            "service"
        ],

        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
        react: {
            wait: true
        }
    })

export default i18n;
