import React, { Fragment, useEffect, useState } from "react";

import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { NavLink,Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { data, image } from "../../utils/constants";
import CallToAction from "../UI/CallToAction";
import LinkItem from "./LinkItem";
import MobileLink from "./MobileLink";
import Dropdown from "../UI/Dropdown";
import DropdownLinkItem from "./DropdownLinkItem";
import MobileDropDownLinkItem from "./MobileDropDownLinkItem";
import MobileDropDown from "./MobileDropDown";

const Navbar = ({value , lngs , handleValue}) => {
    const [hasActive, setActive] = useState(false);
    const [hasActive2, setActive2] = useState(false);
    const [isSticky, setSticky] = useState(false);
    const [isOpened, setOpen] = useState(false);
    const [isOpened2, setOpen2] = useState(false);
    const [isItOpen, setIsOpen] = useState(false);
    const [isItOpen2, setIsOpen2] = useState(false);
    const { t } = useTranslation("common");

    const stickNavbar = () => {
        let windowHeight = window.scrollY;
        if (windowHeight > 200) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    const handleOpen = () => {
        setOpen((prevState) => !prevState);
        setOpen2(!isOpened);
    };

    const handleActive = () => {
        setActive((prevState) => !prevState);
        setActive2(hasActive);
    };


    useEffect(() => {
        window.addEventListener("scroll", stickNavbar);
        return () => window.removeEventListener("scroll", stickNavbar);
    }, []);
    return (
        <Fragment>
        <nav className={isSticky ? "nav sticky-nav" : "nav"}>
        <div className="lg:max-w-[1054px]  xl:max-w-[1271px] mx-auto px-4">
        <div className="flex justify-between items-center md:py-[10px]">
        <div className="flex space-x-7">
        <div className="flex justify-between">
        <Link to={"/"} className="flex items-center py-4 px-2">
        <img
    src={image.logoNove}
    alt="Nove Engineering"
    width={"90px"}
    className="mr-2 hover:scale-110 transition ease duration-500"
        />
        </Link>
        </div>
        </div>
        <div className="hidden lg:flex lg:flex-rows items-center  xl:space-x-12">
        <ul className="lg:flex lg:items-center">
        {data.navData.map((data) => (
                <LinkItem
            key={data.id}
            isOpen={true}
            item={t(`${data.trans}`)}
    path={data.path}
    />
))}
<DropdownLinkItem
    basePath={"service"}
    list={data.subLink}
    textLink={t("services")}
    path="/service"
    transList={"nosService"}
    />
    <DropdownLinkItem
    basePath={"nos-domaines"}
    height={"300px"}
    list={data.domainLink}
    textLink={t("domaines")}
    path="/nos-domaines"
    transList={"nosDomaine"}
    />
    <LinkItem path={"nos-projets"} item={t("projets")} />
    <Dropdown value={value} lngs={lngs} handleValue={handleValue}  />
    <CallToAction />
    </ul>
    </div>

    <div className="lg:hidden flex items-center justify-between">
        <button
    className={`${
        hasActive
            ? "outline-none mobile-menu-button"
            : "mobile-menu-button active"
        } block`}
    onClick={handleActive}
        >
        {hasActive ? (
                <MdClose className="w-[35px] text-[#3998D4] h-full" />
) : (
    <GiHamburgerMenu className="w-[35px] h-full text-[#3998D4]" />
)}
</button>
    </div>
    </div>
    </div>
    <div
    className={`${
        hasActive
            ? "mobile-menu active"
            : "mobile-menu overflow-y-scroll lg:hidden"
        }`}
>
<ul
    className={`${
        hasActive ? "mobile-menu-items active" : "mobile-menu-items"
        } space-y-10 text-center`}
>
    {data.navData.map((data, index) => (
        <MobileLink
        key={data.id}
        item={t(`mobileLink.${index}`)}
        hasActive={hasActive}
        setActive={setActive}
        path={data.path}
        />
    ))}
<MobileDropDownLinkItem
    textLink={t("services")}
    path="#"
    setOpen={setOpen}
    isOpened={isOpened}
    hasActive={hasActive}
    setActive={setActive}
    setIsOpen={setIsOpen}
    />
    <MobileDropDown
    textLink={t("domaines")}
    path="#"
    setOpen2={setOpen2}
    isOpened2={isOpened2}
    hasActive2={hasActive}
    setActive2={setActive}
    setIsOpen2={setIsOpen2}
    />
   
    <MobileLink item={t("projets")} path={"/nos-projets"} hasActive={hasActive}
    setActive={setActive} />
    <div className="flex justify-center">
        <Dropdown value={value} lngs={lngs} handleValue={handleValue} ></Dropdown>
        </div>

        <NavLink
    to={"/contact"}
    className="py-[13px] rounded-md px-[40px] lg:px-[20px] xl:px-[30px] lg:py-[24px] md:px-[30px] md:py-[25px] md:h-12 text-white bg-blue-500 hover:bg-white hover:text-blue-500 border-2 border-transparent transition duration-500 md:flex md:items-center md:justify-center block mx-4"
    onClick={() => setActive(false)}>
    {t("contactezNous")}
</NavLink>
    </ul>
    </div>
    </nav>
    </Fragment>
);
};

export default Navbar;
