import React from 'react';

import { Link } from 'react-router-dom';
import { MdOutlineArrowRightAlt } from 'react-icons/md'
import { useTranslation } from 'react-i18next';

const ViewMoreBtn = ({ projetId }) => {
    const { t } = useTranslation("home");
  return (
    <Link to={`/nos-projets/${parseInt(projetId)}`} className="
    flex gap-x-4 
    items-center px-5 
    py-[15px] border-2 
    w-[180px] lg:flex
    mt-[50px]
    border-blue-500 lg:ml-[45px] 
    lg:w-[170px] text-blue-500 
    uppercase transition-all 
    
    see-more">{t('voirPlus')} <MdOutlineArrowRightAlt className="icon-left text-blue-500" /> </Link>
  )
}

export default ViewMoreBtn