import React, { Fragment, useEffect, useState } from 'react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';

import { image } from '../../utils/constants'
import { t } from 'i18next';
import { FlatTree } from 'framer-motion';

const Testimonial = () => {

    const { t } = useTranslation("home");
    let [count, setCounter] = useState(0);
    const [active, setActive] = useState(false);
    const [preActive, setPreActive] = useState(false);
    
    const settings = {
        infinite: true,
        dots: true,
        initialSlide: 0,
        lazyLoad: true,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
            }
        ]
    };

    const testimonias = [
        {
            id: 1,
            img: image.testimonial_man,
        },
        {
            id: 2,
            img: image.testimonial_woman,
        },
        {
            id: 3, 
            img: image.testimonial_woman
        },
        {
            id: 4, 
            img: image.testimonial_man
        }
    ];

    const handleNextCounter = () => {
        setCounter((prevState) => prevState + 1);
        setActive(true);
        setPreActive(false);
        if ( count === testimonias.length - 1) {
            setCounter(0);
        }
        return count;
    }

    const handlePrevCounter = () => {
        setCounter((prevState) => prevState - 1);
        setPreActive(true);
        setActive(false)
        if (count === 0) {
            setCounter(testimonias.length - 1);
        }
    }

   useEffect(() => {
     const slideInterval = setInterval(handleNextCounter, 10000);
   
     return () => {
       clearInterval(slideInterval)
     }
   }, [count])

  return (
    <Fragment>
        <section  className="testimonial py-[90px] md:py-[90px] lg:py-[115px]">
            <div className="content-wrapper">
                <h2 className="headline-border">{t('testimonialsHeading')}</h2>
                <div className="content mt-[90px] lg:mt-[210px] lg:mb-[150px]">
                    <div className="desktop hidden lg:block lg:max-w-[879px] mx-auto my-0">
                        <div className="user-container grid grid-cols-2 place-items-center relative">
                            <div className="user relative w-full">
                                <div className="content py-4 pb-10 bg-blue-50 w-[303px] flex items-center justify-start flex-col z-10 relative rounded-md ">
                                    <span className={
                                        `${active ? "slide-img active" : "slide-img"}block px-4 h-[292px] transition-all w-full slide-img overflow-hidden`
                                    }>
                                        <img 
                                        src={testimonias[count].img} 
                                        alt="" 
                                        className="h-full w-full object-cover block rounded-[5px]" />
                                    </span>
                                    <div className="desc text-left w-full pl-4 py-5">
                                        <h5 className='font-bold text-blue-800 text-[20px]'>{t(`testimonialObject.${count}.name`)}</h5>
                                        <p className='text-black-300'>{t(`testimonialObject.${count}.title`)}</p>
                                    </div>
                                </div>
                                <span className="blue-bar w-[202px] h-[532px] bg-blue-500 block absolute -top-[44px] left-[12%]"></span>
                            </div>
                            <div className="desc">
                                <div className="small-bar absolute top-[65px] left-[390px] w-[433px] bg-blue-700 h-[6px]"></div>
                                <div className="text ">
                                    <p>{t(`testimonialObject.${count}.text`)}</p>
                                    <div className="quote absolute -bottom-[-0px] left-[800px]"><img src={image.iconQuotes} alt="icon quote" /></div>
                                    
                                    <div className="
                                    control w-[100px] flex 
                                    gap-x-2 absolute 
                                    -bottom-[-25px] left-[440px]
                                    ">
                                        <span 
                                        className={`${preActive ? "bg-blue-400" : ""} hover:opacity-70 cursor-pointer transition-all p-4 w-[50%] bg-blue-100 flex justify-center items-center`}
                                        onClick={handlePrevCounter}
                                        ><img src={image.arrowLeft} alt="icon arrow left" /></span>
                                        <span 
                                        className={
                                            `${active ? "bg-blue-400" : "bg-blue-100"} hover:opacity-70 cursor-pointer 
                                            transition-all p-4 w-[50%] 
                                            flex 
                                            justify-center items-center`
                                        }
                                        onClick={handleNextCounter}
                                        ><img src={image.arrowRight} alt="icon arrow right" /></span>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className="mobile lg:hidden">
                        <div className="testimonial-container grid grid-cols-1 ">
                            <Slider {...settings}>
                                {
                                    testimonias.map((item, index) => (
                                        <div className="card-ui bg-blue-50 py-8 px-4">
                                            <img src={item.img} alt={t(`testimonialObject.${index}.name`)} className='w-[80px] h-[80px] rounded-full object-cover'/>
                                            <div className="desc my-[30px]">
                                                <img src={image.iconQuotes} alt="icon of quote" className='w-[40px]' />
                                                <p className='my-8 text-black-600'>{t(`testimonialObject.${index}.text`)}</p>

                                                <h4 className='font-semibold text-lg text-blue-800'>{t(`testimonialObject.${index}.name`)}</h4>
                                                {/* <h6 className='text-black-100'>{t(`testimonialObject.${index}.titlte`)}</h6> */}
                                            </div>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
  )
}

export default Testimonial