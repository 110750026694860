import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import { Link } from 'react-scroll';

import { data } from '../../utils/constants';


const ProjetNav = () => {
    
    const { t } = useTranslation("projets");
  return (
    <Fragment>
        <div className="nav-service sticky top-[130px] left-[150px] translate-y-8 lg:w-[193px] lg:hidden lg:h-[263px] hidden xl:hidden 2xl:block projet-nav-service">
            <div className="service-link flex items-center relative">
                <ul className="order-2">
                    {
                        data.domainesLinks.map((link, index) => (
                            <li key={link.id} className="text-[12px] slider-nav">
                                <Link 
                                to={`${link.link}`} 
                                activeClass="active"
                                spy={true} 
                                smooth={true} 
                                offset={-110}
                                isDynamic={true}
                                duration={-150} 
                                delay={500}
                                className="cursor-pointer block py-2 mt-2 pl-[33px]"
                                >
                                {t(`projectObject.${index}.name`).toUpperCase()}
                                </Link>
                            </li>
                        ))
                    }
                </ul>
                <span className="line-slider block w-[2px] h-[190px] bg-blue-100 "></span>
            </div>
        </div>
    </Fragment>
  )
}

export default ProjetNav