import React, { Fragment, useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";

import { useTranslation } from "react-i18next";
import axios, { isCancel, AxiosError } from "axios";
import { Input } from "../../components";
import { data, image } from "../../utils/constants";
import apiConfig from "../../apiConfig.json";
import Swal from "sweetalert2";
import { langContext } from "../../App";

const ContactForm = () => {
  const contactEvent = () => {
    ReactGA.event({
      category: "Contact",
      action: "essaie de contact",
      label: "Bouton de contact",
    });
  };

  const submitFormEvent = () => {
    ReactGA.event({
      category: "Contact",
      action: "Soummission du formulaire de contact",
      label: "Formulaire de contact",
    });

    alert("fdkgmdkmgdkd") ;
  };
  const { t } = useTranslation("contact");
  const lang = useContext(langContext);

  const [loader, setLoader] = useState(false);

  const [validationErrors, setVailidationErrors] = useState({});

  const handleLoader = () => {
    setLoader((loader) => !loader);
  };

  const [dataObject, setData] = useState({
    name: "",
    email: "",
    emailEntreprise: apiConfig.enPoints.sendMail.emailEntreprise,
    object: "",
    objectConfirmation: apiConfig.enPoints.sendMail.object,
    message: "",
    messageConfirmation: apiConfig.enPoints.sendMail.messageUtilisateur,
    lang: "fr",
  });
  console.log(validationErrors);
  useEffect(() => {
    setData({ ...dataObject, lang: lang });
  }, [lang]);

  const handleTexarea = (e) => {
    
    const value = e.target.value;
    setData({ ...dataObject, message: value });
  
  };
  const handleInput = (e) => {
    e.preventDefault();
    const InputValue = e.target.value;
    const name = e.target.name;

    switch (name) {
      case "name":
        setData({ ...dataObject, name: InputValue });
        break;
      case "email":
        setData({ ...dataObject, email: InputValue });
        break;
      case "object":
        setData({ ...dataObject, object: InputValue });
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();

    try {
      const response = await axios.post(
        apiConfig.enPoints.sendMail.url,
        dataObject,
        {
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
          },
        }
      );

      if (response.data.status) {

        submitFormEvent() ;
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your email has been sent successfuly",
          showConfirmButton: false,
          timer: 3000,
        });
      }

      setData({ ...dataObject, name: "", email: "", message: "", object: "" });
      setVailidationErrors({});
    } catch (error) {
      if (error.request.status == 422) {
        setVailidationErrors(error.response.data.message);
      }
    }

    setLoader(false);
  };

  return (
    <Fragment>
      <section className="py-[90px] lg:py-[115px] bg-black-50">
        <div className="content-wrapper">
          <div className="flex flex-col gap-y-8 md:grid md:grid-cols-2 md:gap-x-8 lg:flex lg:flex-row lg:gap-x-[54px] xl:gap-x-[74px]">
            <div className="address w-4/4 lg:w-2/4 relative">
              <div className="address-detail relative z-10 h-full py-[80px] px-[40px] lg:px-[44px] lg:pt-[88px] xl:px-[88px] lg:pb-[314px] xl:">
                <div className="desc">
                  <h4 className="uppercase text-white font-bold text-[20px]">
                    NOVE Engineering
                  </h4>
                  <p className="text-black-50 py-4">{t("contactSlogan")}</p>

                  <ul className="detail flex flex-col gap-y-5 py-8">
                    <li className="flex items-center gap-2">
                      <img src={image.iconPhone} alt="icon phone" />
                      <span className="w-[150px] text-sm block text-white">
                        +237 675 147 996
                      </span>
                    </li>
                    <li className="flex items-center gap-2">
                      <img src={image.iconEmail} alt="icon email" />
                      <span className="w-[150px] break-words lg:break-normal text-white text-sm">
                        contact@novengineering.com
                      </span>
                    </li>
                    <li className="flex items-center gap-2">
                      <img src={image.iconLocation} alt="icon location" />
                      <span className="w-[150px] text-sm block text-white">
                        {t("contactAddress")}
                      </span>
                    </li>
                  </ul>

                  <span className="bottom-block w-full h-[26px] lg:h-[52px] left-0 bg-blue-900 block absolute bottom-0"></span>
                </div>
              </div>
              <span className="square-block hidden w-full h-[709px] xl:h-[714px] lg:block lg:absolute lg:top-[22px] lg:left-[24px] bg-blue-500 opacity-50"></span>
            </div>

            <div className="form-container w-4/4 lg:w-2/4">
              <form
                className="space-y-[20px] md:space-y-[30px] xl:space-y-[60px]"
                onSubmit={(e) => handleSubmit(e)}
              >
                {data.inputs.map((input, index) => (
                  <Input
                    key={input.id}
                    label={t(`contactInputObject.${index}.name`)}
                    type={input.type}
                    name={input.name}
                    validationErrors={validationErrors}
                    data={dataObject}
                    handleInput={handleInput}
                  />
                ))}
                <div className="form-group flex flex-col gap-y-3">
                  <textarea
                    name="message"
                    onChange={(e) => handleTexarea(e)}
                    value={dataObject.message}
                    placeholder="Message"
                    className="h-[150px] focus:ring-1 md:h-[300px] lg:h-[200px] focus:ring-blue-200 focus:outline-blue-500 px-[20px] py-[16px]"
                    required
                  ></textarea>
                  <div className="text-[red] text-xs">
                    {" "}
                    {validationErrors.message}
                  </div>
                </div>
                <div className=" flex flex-col mt-[30px] lg:mt-[91px]">
                  <button
                    type="submit"
                    className={
                      loader
                        ? "animate-pulse bg-blue-500 text-white p-[16px] h-[57px] hover:bg-white hover:text-blue-500 border-2 border-transparent hover:border-2 hover:border-blue-500 transition duration-500"
                        : " bg-blue-500 text-white p-[16px] h-[57px] hover:bg-white hover:text-blue-500 border-2 border-transparent hover:border-2 hover:border-blue-500 transition duration-500"
                    }
                    disabled={loader}
                    onClick={contactEvent}
                  >
                    {t("contactUs")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ContactForm;
