import React, { Fragment, useEffect } from 'react';

import Aos from 'aos';
import 'aos/dist/aos.css';

const ValueCard = ( {img, title, content, id} ) => {
  
  useEffect(() => {
    Aos.init({once: true});
  })

  return (
    <Fragment>
        <div 
        className="card-valeur relative"
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="500"
        data-aos-delay={`${300 * id}`}
        >
            <div className="card-icon w-[80px] h-[80px] bg-blue-500 flex items-center justify-center rounded-full border-[8px] border-blue-100 absolute -top-[44px] left-[16px] tra">
                <img src={img} alt="cion flag" className="w-[22px]" />
            </div>
            <div className="card-desc">
                <h4 className="text-blue-500 text-[24px] capitalize font-bold  mb-[17px] lg:mb-[34px]">{title}</h4>
                <p>{content}</p>
            <span className="small-line w-28 bg-blue-500 h-1 block mt-5 lg:mt-[22px]"></span>
            </div>
        </div>
    </Fragment>
  )
}

export default ValueCard