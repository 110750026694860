import React, { Fragment, useState } from "react";

const Input = ({ label, name, type, validationErrors, data, handleInput }) => {
  const key = name;
  const inputErrors = [];

  if (validationErrors) {
    const keys = Object.keys(validationErrors);

    keys.map((key) => {
      if (key === name) {
        validationErrors[key].map((error) => {
          inputErrors.push(error);
        });
      }
    });
  }

  return (
    <Fragment>
      <div className="form-group w-full flex flex-col gap-y-3">
        {type === "email" ? (
          <input
            required
            type={type}
            name={name}
            pattern="[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            placeholder={label}
            value={data[name]}
            className="w-full bg-white p-[16px] h-[57px] focus:ring-1 focus:ring-blue-200 focus:outline-blue-500 text-black-700 "
            onChange={(event) => handleInput(event)}
          />
        ) : (
          <input
            required
            type={type}
            name={name}
            placeholder={label}
            value={data[name]}
            className="w-full bg-white p-[16px] h-[57px] focus:ring-1 focus:ring-blue-200 focus:outline-blue-500 text-black-700"
            onChange={(event) => handleInput(event)}
          />
        )}

        <div className="text-[red] text-xs"> {inputErrors}</div>
      </div>
    </Fragment>
  );
};

export default Input;
