import React, { useState } from "react";

import { NavLink, Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { data } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const MobileDropDown = ({
                                          path,
                                          textLink,
                                          hasActive2,
                                          setActive2,
                                          setOpen2,
                                          isOpened2,
                                          setIsOpen2,
                                      }) => {
    const handleHasActive = () => {
        if (hasActive2 === true) {
            setActive2(false);
        } else setActive2(true);
    };
    const {t} = useTranslation("common");

    const handleStateChange = () => {
        setOpen2(!isOpened2);
        setIsOpen2(false);
    };
    return (
        <li className="link-dropdown flex justify-center items-center flex-col px-3">
        <a
    href={path}
    onClick={() => handleStateChange()}
    className={
            isOpened2 ? "link-item active font-bold" : "link-item justify-center"
        }
        >
        <span className="flex items-center ">
        <span className="block">{textLink}</span>
        <IoIosArrowDown
    onClick={() => setOpen2(!setOpen2)}
    className={
        isOpened2
                ? "rotate-180 transition-all"
            : "rotate-0 text-blue-500 font-bold block ml-2"
    }
    />
    </span>
    </a>
    <ul className={isOpened2 ? "active mt-2 rounded-sm" : ""}>
        {data.domainLink.map((item, index) => (
                <li key={item.id} className="item-select">
            <NavLink
            to={`/${'nos-domaines'}/${item.path}`}
    className="text-[12px]"
    onClick={(e) => {
        handleHasActive();
        setOpen2(!setOpen2);

    }}
>
    {t(`secteurActivite.${index}`)}
</NavLink>
    </li>
))}
</ul>
    </li>
);
};

export default MobileDropDown;
