import React, { Fragment } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const Map = () => {

    const mapStyles = {
        height: "100%",
        width: "100%"
    }
    const defaultCenter = {
        lat: 3.898862, 
        lng: 11.525288
    }
    const location = {
        name: "Mbala 2, Yaoundé",
        address: {
            lat: 3.898862, 
            lng: 11.525288
        }
    }
  return (
    <Fragment>
        <section className="map-section bg-white h-[400px] md:h-[500px] lg:h-[664px] w-full">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d828.1593881706544!2d11.5249472749949!3d3.898807059441147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x108bc5f80c69012d%3A0xee06083d1ae76f53!2sLyc%C3%A9e%20de%20Mballa%202%2C%20Yaound%C3%A9!5e0!3m2!1sfr!2scm!4v1666202615018!5m2!1sfr!2scm" style={{width: "100%", height: "100%"}}  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </section>
    </Fragment>
  )
}

export default Map