import image from './image';

const partenaires = [
    {
        href:"https://microfinance-ccm.com",
        scr:image.logoCRM,
        alt:"logo Engie",

    },
    {
        href:"http://www.groupecrebat.com/",
        scr:image.logoCrebat,
        alt:"logo Razel",

    },
    {
        href:"https://aspacintl.com/",
        scr:image.logoAspac,
        alt:"logo Aspac",

    },
    {
        href:"https://crid-cam.net/",
        scr:image.logoCrid,
        alt:"logo crid",

    },
    {
        href:"https://www.unops.org/",
        scr:image.logoUNDPS,
        alt:"logo UNoPS",

    },
    {
        href:"https://www.unesco.org",
        scr:image.unesco,
        alt:"logo unesco",

    },
    {
        href:"https://cdimsarl.com/",
        scr:image.cdim,
        alt:"logo cdim",

    },
    {
        href:"https://www.facebook.com/amtisstudio/",
        scr:image.amtis,
        alt:"logo amtis",

    },
    {
        href:"https://www.facebook.com/ndeforcobarchitects/",
        scr:image.nfa,
        alt:"logo nfa",

    }


]

export default {
    partenaires
}