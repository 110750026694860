import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import { ValueCard } from '../../components';
import { data } from '../../utils/constants';


const Valeur = () => {
    const { t } = useTranslation("about-us");
  return (
    <Fragment>
        <section className="section-valeur py-[90px] lg:py-[158px]">
            <div className="content-wrapper">
                <h2 className="headline-border">{t('aboutWhyChooseUsHeading')}</h2>

                <div className=" xl:hidden card-wrapper mt-[140px] grid grid-cols-1 gap-y-[100px] lg:grid-cols-3 lg:gap-x-8">
                    {
                        data.valeurs.map((valeur, index) => (
                            <ValueCard
                            key={valeur.id} 
                            title={t(`aboutWhyChooseUsObject.${index}.title`)}
                            content={t(`aboutWhyChooseUsObject.${index}.desc`)}
                            img={valeur.icon}
                            />
                        ))
                    }
                </div>
            </div>
            <div className="hidden wrapper xl:mt-[140px] xl:grid-cols-3 xl:grid lg:grid-cols-3 lg:gap-x-8 xl:gap-x-4">
                {
                    data.valeurs.map((valeur, index) => (
                        <ValueCard
                        key={valeur.id} 
                        title={t(`aboutWhyChooseUsObject.${index}.title`)}
                        content={t(`aboutWhyChooseUsObject.${index}.desc`)}
                        img={valeur.icon}
                        id={valeur.id}
                        />
                    ))
                }
            </div>
        </section>
    </Fragment>
  )
}

export default Valeur