import React from "react";

/* batiment */

/* projet tradex */

import tradexImg1 from "../../assets/imgs/img-projet/batiment/img-tradex/34.jpg";
import tradexImg2 from "../../assets/imgs/img-projet/batiment/img-tradex/23.jpg";

/* projet CRID */
import immeubleCrid from "../../assets/imgs/img-projet/batiment/img-crid/crid.jpg";
import plaqueCrid from "../../assets/imgs/img-projet/batiment/img-crid/26.jpg";

/* projet batiment administratif kribi */
import baKribi from "../../assets/imgs/img-projet/batiment/img-kribi/bapk.jpg";

/* projet d'habitation r+ 1*/
import planR1 from "../../assets/imgs/img-projet/batiment/img-r+1/14.jpg";
import plan2R1 from "../../assets/imgs/img-projet/batiment/img-r+1/18.jpg";
import batiment1R1 from "../../assets/imgs/img-projet/batiment/img-r+1/16.jpg";
import batiment2R1 from "../../assets/imgs/img-projet/batiment/img-r+1/11.jpg";

/* construction d'un batiment R2 */
import planR2 from "../../assets/imgs/img-projet/batiment/img-rdc+2/9.jpg";
import batimentR2 from "../../assets/imgs/img-projet/batiment/img-rdc+2/4.jpg";

/* btc */
import btc from "../../assets/imgs/img-projet/batiment/btc/N6.jpg"
import btc2 from "../../assets/imgs/img-projet/batiment/btc/N7.jpg"

/* hydrolique */

/* filtre-pak */
import imgFiltre from "../../assets/imgs/img-projet/hydrolique/img-filtre-pak/img-filtre1.jpg";

/* reservoir-pak */

import imgPak1 from "../../assets/imgs/img-projet/hydrolique/img-reservoir-pak/pak-1.jpg";
import imgPak2 from "../../assets/imgs/img-projet/hydrolique/img-reservoir-pak/pak-2.jpg";

/* wenchi-water */

import wenchi1 from "../../assets/imgs/img-projet/hydrolique/img-wnchi-water/img1.jpg";
import wenchi2 from "../../assets/imgs/img-projet/hydrolique/img-wnchi-water/img2.jpg";

/* le dessableur */
import dessableur1 from "../../assets/imgs/img-projet/hydrolique/img-dessableur/1.jpg";

/* bloc process ghana */
import bpw1 from "../../assets/imgs/img-projet/hydrolique/bloc-process-wenchi/N2.jpg";
import bpw2 from "../../assets/imgs/img-projet/hydrolique/bloc-process-wenchi/N2+.jpg";
import bpw3 from "../../assets/imgs/img-projet/hydrolique/bloc-process-wenchi/N2++.jpg"

/* bloc process kribi */
import bpk from "../../assets/imgs/img-projet/hydrolique/bloc-process-kribi/N5.jpg";

/* construction du contact tank */
import cct from "../../assets/imgs/img-projet/hydrolique/img-contact-tank/N3.jpg"

export default {
  tradexImg1,
  tradexImg2,
  immeubleCrid,
  plaqueCrid,
  baKribi,
  plan2R1,
  planR1,
  batiment1R1,
  batiment2R1,
  planR2,
  batimentR2,
  btc,
  btc2,
  dessableur1,
  imgFiltre ,
  imgPak1 ,
  imgPak2 ,
  wenchi1 ,
  wenchi2 ,
  bpw1,
  bpw2,
  bpw3,
  bpk,
  cct,


  
};
