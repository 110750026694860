import React, { Fragment } from 'react'

const TabButton = ({ button }) => {
  return (
    <Fragment>
        <button value={button} className="px-[15px] mb-3 mr-2 md:mb-0 py-[10px] rounded-md bg-transparent border-2 border-blue-500 text-black-900 hover:border-transparent hover:bg-blue-500 hover:text-white transition-all active:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300">{button}</button>
    </Fragment>
  )
}

export default TabButton