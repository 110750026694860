import React from "react";
import image from "./image";
import imageProjets from "./imageProjets";
const projects = [
  
  {
    id: 1,
    domaineId: 1,
    serviceId:2,
    name: "CONSTRUCTION DE LA STATION TRADEX D’EKOREZOCK",
    description: "Construction de la boutique et de la baie de graissage de la station-service de Tradex.",
    pays: "Cameroun",
    ville: "Yaoundé",
    date: "2019",
    secteurDactivite: "Bâtiment",
    sousSecteur: "Bâtiment  à usage commercial",
    servicesRendus: ["Suivi et Coordination des Travaux"],
    client: "TRADEX",
    sousProjets: [],
    imgs: [imageProjets.tradexImg1 ,imageProjets.tradexImg2],
    entreprise: "CREBAT SARL"
  },
  {
    id: 2,
    domaineId: 1,
    serviceId:3,
    name: "CONSTRUCTION D’UN BATIMENT RDC +2 A USAGE D’HABITATION",
    description: "C’est un bâtiment à usage d’habitation implanté sur une emprise de 310m².",
    pays: "Cameroun",
    ville: "Yaoundé",
    date: "2017 - 2019",
    secteurDactivite: "Bâtiment",
    sousSecteur: "",
    servicesRendus: [
      "Conception" ,  "Etudes" , "Suivi et coordination des Travaux",
    ],
    client: "Un particulier",
    sousProjets: [],
    imgs: [imageProjets.planR2 , imageProjets.batimentR2],
    entreprise: "",
  },
  {
    id: 3,
    domaineId: 2,
    serviceId:1,
    name: "CONSTRUCTION D'UN RESERVOIR DE REPRISE DE 1500 M3 D'ALIMENTATION EN EAU POTABLE",
    description:"Le réservoir de reprise de 1500 m3 est un ouvrage de retenue d’un diamètre intérieur de 25 m et d’une hauteur d’environ 5m.",
    pays: "Cameroun",
    ville: "KRIBI",
    date: "2020- 2021",
    secteurDactivite: "Ouvrage hydraulique",
    sousSecteur: "",
    servicesRendus: ["Etudes" ,  "Plans d’exécutions"],
    client: "Port Autonome de Kribi (PAK)",
    sousProjets: [],
    imgs: [imageProjets.imgPak1 , imageProjets.imgPak2 ],
    entreprise: "ASPAC International",
  },
  {
    id: 4,
    domaineId: 2,
    serviceId:3,
    name: "CONTRUCTION D'UN FILTRE AUTONETTOYANT",
    description:"Il s’agit d’un ouvragre support des filtres de grande capacité et à géométrie symétrique.",
    pays: "Cameroun",
    ville: "KRIBI",
    date: "2019 - 2020",
    secteurDactivite: "Ouvrage hydraulique",
    sousSecteur: "",
    servicesRendus: ["Etudes" ,  "Plans d’exécutions"],
    client: "Port Autonome de Kribi (PAK)",
    sousProjets: [],
    imgs: [imageProjets.imgFiltre],
    entreprise: "ASPAC International",
  },

  {
    id: 5,
    domaineId: 1,
    serviceId:1,
    name: "CONSTRUCTION DU SIEGE DU CENTRE FOR RESEARCH IN INFECTIOUS DISEASES",
    description:"Il s’agit d’un Bâtiment RDC+4 abritant le siège du CRID et implanté sur une superficie de 400 m².",
    pays: "Cameroun",
    ville: "Yaoundé",
    date: "2020 - 2021",
    secteurDactivite: "Bâtiment",
    servicesRendus: ["Etudes architecturales" , "Etudes et Plans d'execution"],
    sousSecteur: "",
    client: "CENTRE FOR RESEARCH IN INFECTIOUS DISEASES (CRID)",
    sousProjets: [],
    imgs: [imageProjets.immeubleCrid , imageProjets.plaqueCrid],
    entreprise: "",
  },
  {
    id: 6,
    domaineId: 2,
    serviceId:1,
    name: " WENCHI WATER SUPPLY PROJECT : PROCESS BLOCK",
    description:"Le process block est un ouvrage essentiel dans le processus de potabilisation de l’eau.",
    pays: "GHANA",
    ville: "Wenchi",
    date: "2020 - 2021",
    secteurDactivite: "Ouvrage hydraulique",
    sousSecteur: "",
    servicesRendus: ["Etudes" ,  "plans d’exécutions"],
    client: "GHANA WATER COMPAGNY LIMITED",
    sousProjets: [],
    imgs: [imageProjets.wenchi1 , imageProjets.wenchi2],
    entreprise: "ASPAC International",
  },
  {
    id: 7 ,
    serviceId:3,
    domaineId: 2,
    name: " CONSTRUCTION D'UN DESSABLEUR",
    description:"Le Dessableur est un ouvrage hydraulique principalement en béton armé et symétrie dans sa géométrie.",
    pays: "Cameroun",
    ville: "KRIBI",
    date: "2020-2021",
    secteurDactivite: "Ouvrage hydraulique",
    sousSecteur: "",
    servicesRendus: ["Etudes" ,  "plans d’exécutions"],
    client: "Port Autonome de Kribi (PAK)",
    sousProjets: [],
    imgs: [imageProjets.dessableur1],
    entreprise: "ASPAC International",
  },

  {
    id: 8 ,
    serviceId:1,
    domaineId: 1,
    name: "PROJET D’ALIMENTATION EN EAU POTABLE DU PORT AUTONOME DE KRIBI ET SES DEPENDANCES : BATIMENT ADMINISTRATIF",
    description:"Il s’agit d’un bâtiment RDC+1.",
    pays: "Cameroun",
    ville: "KRIBI",
    date: "2019-2020",
    secteurDactivite: "Bâtiment",
    sousSecteur: "",
    servicesRendus: ["Etudes" ,  "plans d’exécutions"],
    client: "Port Autonome de Kribi (PAK)",
    sousProjets: [],
    imgs: [imageProjets.baKribi],
    entreprise: "ASPAC International",
  },
  {
    id: 9 ,
    serviceId:1,
    domaineId: 2,
    name: "CONSTRUCTION DU CONTACT TANK",
    description:"Le projet d’approvisionnement en eau potable de Wenchi vise à produire quotidiennement douze mille mètres cubes (12 000 m³) pour desservir les habitants de Wenchi et des villes voisines. Le Contact tank fait partie des réservoirs de stockage d’eau dans le processus de potabilisation.",
    pays: "Ghana",
    ville: "Wenchi",
    date: "2022-2023",
    secteurDactivite: "Infrastructure",
    sousSecteur: "",
    servicesRendus: ["Etudes d’exécution"],
    client: "GHANA WATER COMPANY LIMITED",
    sousProjets: [],
    imgs: [imageProjets.cct],
    entreprise: "ASPAC International",
  },
  {
    id: 10 ,
    serviceId:1,
    domaineId: 2,
    name: "BLOCK PROCESS",
    description:"Le projet d’approvisionnement en eau potable de Wenchi vise à produire quotidiennement douze mille mètres cubes (12 000 m³) pour desservir les habitants de Wenchi et des villes voisines. Le Block Process fait partie de la chaine des ouvrages de traitement d’eau et est constitué d’un ouvrage de cascade et aération, d’un floculateur et d’un bassin de sédimentation",
    pays: "Ghana",
    ville: "Wenchi",
    date: "2022-2023",
    secteurDactivite: "Infrastructure",
    sousSecteur: "",
    servicesRendus: ["Etudes d’exécution"],
    client: "GHANA WATER COMPANY LIMITED",
    sousProjets: [],
    imgs: [imageProjets.bpw1,imageProjets.bpw2,imageProjets.bpw3],
    entreprise: "ASPAC International",
  },
  {
    id: 11 ,
    serviceId:1,
    domaineId: 2,
    name: "BLOCK PROCESS",
    description:"Le projet d’approvisionnement en eau potable de Wenchi vise à produire quotidiennement cinq mille mètres cubes (5 000 m³) pour desservir les le port en eau profonde de Kribi et ses environs. Le Block Process fait partie de la chaine des ouvrages de traitement d’eau et est constitué d’un ouvrage de cascade et aération, d’un floculateur et d’un bassin de sédimentation.",
    pays: "Cameroun",
    ville: "Kribi",
    date: "2021-2022",
    secteurDactivite: "Infrastructure",
    sousSecteur: "",
    servicesRendus: ["Etudes d’exécution"],
    client: "Port Autonome de Kribi (PAK)",
    sousProjets: [],
    imgs: [imageProjets.bpk],
    entreprise: "ASPAC International",
  },

  {
    id: 12 ,
    serviceId:1,
    domaineId: 1,
    name: "BATIMENT TECHNIQUE DE CAPTAGE",
    description:"Le projet d’approvisionnement en eau potable de Kribi vise à produire quotidiennement cinq mille mètres cubes (5 000 m³) pour desservir le port en eau profonde de Kribi et ses environs. Le bâtiment technique de captage abrite les équipements de captage d’eau.",
    pays: "Cameroun",
    ville: "KRIBI",
    date: "2021-2022",
    secteurDactivite: "Bâtiment",
    sousSecteur: "",
    servicesRendus: ["Etudes d’exécution"],
    client: "Port Autonome de Kribi (PAK)",
    sousProjets: [],
    imgs: [imageProjets.btc, imageProjets.btc2],
    entreprise: "ASPAC International",
  },
  
];

const domaines = [
  {
    id: 1 ,
    title : "Bâtiment" ,
    link : "Bâtiment" ,
    img : image.batiment
  }  , 
  {
   id: 2 ,
   title : "Infrastructure" ,
   link : "Infrastructure" ,
   img : image.infrastructure ,
 } ,
  {
    id: 3,
    title : "Ouvrages d’Art" ,
    link : "Ouvrages d’Art"  ,
    img : image.ouvrageDart
  },
  {
    id: 4,
    title : "Energie" ,
    link : "Energie" ,
    img : image.energie
  }
] ;

const favoritesProjects = [];
favoritesProjects.push(projects[0], projects[5]);
export default {
  projects,
  domaines,
  favoritesProjects,
};
