import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { image } from '../../utils/constants'

import { projectData } from '../../utils/constants';

const Footer = () => {

    const { t, i18n } = useTranslation('common');
    const calculateAllRightYear = () => {
        const currentYear = new Date();
        return currentYear.getFullYear();
    }
    return (
        <Fragment>
            <footer className="p-4 bg-blue-900 sm:p-6 border-b-4 border-blue-400 py-[90px]">
                <div className="content-wrapper mx-auto px-4 md:flex md:justify-between md:flex-col xl:flex-row md:mt-16">
                    <div className="mb-6 md:mb-0 md:-mt-3">
                        <Link to={"/"} className="flex flex-col items-start">
                            <img src={image.logoFooter} className="mr-2 hover:scale-110 transition ease duration-500" alt="Nove white logo" /><br />
                            <span className="font-regular text-black-100 mt-5 md:mr-20 md:w-[350px] block">{t('footerDesc')}</span>
                        </Link>
                    </div>
                    <div className="grid grid-cols-1 gap-8 sm:gap-4 sm:grid-cols-3 lg:grid-cols-3 sm:mt-[90px] xl:mt-0">
                        <div className="w-full">
                            <h2 className="mb-6 text-[18px] leading-[30px] lg:text-[22px] font-semibold text-blue-400">{t('services')}</h2>
                            <ul className="text-black-100 w-full text-[16px] md:text-[14px]">
                                <li className="mb-3">
                                    <Link to="service/etudes-structures-dexecusion" className="hover:font-bold transition ease duration-300 normal-case
 ">{t('maitriseDouvrageDelegue')}</Link>
                                </li>
                                <li className="mb-3">
                                    <Link to="service/management-de-projet" className="hover:font-bold transition ease duration-300 normal-case
 ">{t('conceptionEtEtude')}</Link>
                                </li>
                                <li className="mb-3">
                                    <Link to="service/audit-et-conseils" className="hover:font-bold transition ease duration-300 normal-case
 ">{t('auditEtConseil')}</Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-[18px] leading-[30px] lg:text-[22px] font-semibold text-blue-400">{t('projets')}</h2>
                            <ul className="text-black-100 text-[16px] md:text-[14px]">
                                <li className="mb-3">
                                    <Link to={"nos-projets/1"} className="hover:font-bold transition ease duration-300 normal-case
 ">Construction de la station TRADEX d’Ekorezock</Link>
                                </li>
                                <li className="mb-3">
                                    <Link to={"nos-projets/4"} className="hover:font-bold transition ease duration-300 normal-case
 ">Construction d'un filtre autonettoyant</Link>
                                </li>
                                <li className="">
                                    <Link to={"nos-projets/7"} className="hover:font-bold transition ease duration-300normal-case
 ">Alimentation en eau potable du port autonome de Kribi et ses dépendances : le dessableur</Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-[18px] leading-[30px] lg:text-[22px] font-semibold text-blue-400">{t('secteurDactivites')}</h2>
                            <ul className="text-black-100 text-[16px] md:text-[14px]">
                                <li className="mb-3">
                                    <Link to={"nos-domaines/batiments"} className="hover:font-bold normal-case
 transition ease duration-300 ">{t('batiment')}</Link>
                                </li>
                                <li className="mb-3">
                                    <Link to={"nos-domaines/infrastructure"} className="hover:font-bold normal-case
 transition ease duration-300 ">{t('infrastructures')}</Link>
                                </li>
                                <li className="mb-3">
                                    <Link to={"nos-domaines/energie"} className="hover:font-bold normal-case
 transition ease duration-300 ">{t('energie')}</Link>
                                </li>
                                <li className="mb-3">
                                    <Link to={"nos-domaines/ouvrage-d'art"} className="hover:font-bold normal-case
 transition ease duration-300 ">{t('ouvrageDart')}</Link>
                                </li>
                            </ul>
                        </div> 
                    </div>
                </div>
                <div className="flex items-center justify-center mt-6 mb-4">
                    <span className="text-sm text-white text-center">&copy; {`${calculateAllRightYear()}, NOVE Engineering`}
                    </span>
                </div>
            </footer>
        </Fragment>
    )
}

export default Footer