import image from "./image";

const computeProjectDuration = (startDate, enDate) => {
  return Math.abs(enDate - startDate);
};

const navData = [
  {
    id: 1,
    item: "Accueil",
    path: "/",
    trans: "accueil",
  },
  {
    id: 2,
    item: "A Propos",
    path: "a-propos",
    trans: "aPropos",
  },
];

const subLink = [
  {
    id: 1,
    link: "ETUDES STRUCTURELLESET D’EXÉCUTION",
    path: "etudes-structures-dexecusion",
  },
  {
    id: 2,
    link: "MANAGEMENT DE PROJET",
    path: "management-de-projet",
  },
  {
    id: 3,
    link: "AUDIT ET CONSEILS",
    path: "audit-et-conseils",
  },
];

const domainLink = [
  {
    id: 1,
    link: "Batiment",
    path: "batiments",
  },
  {
    id: 2,
    link: "Infrastructure",
    path: "infrastructure",
  },
  {
    id: 3,
    link: "Energie",
    path: "energie",
  },
  {
    id: 4,
    link: "Ouvrage D'art",
    path: "ouvrage-d'art",
  },
];

const inputs = [
  {
    id: 1,
    label: "Nom Complet",
    name: "name",
    type: "text",
  },
  {
    id: 2,
    label: "Email",
    name: "email",
    type: "email",
  },
  {
    id: 3,
    label: "Objet",
    name: "object",
    type: "text",
  },
];

const teamMembers = [
  {
    id: 1,
    name: "Constant TSAMO ",
    role: "Fondateur de NOVE Engineering",
    desc: "Je suis constant TSAMO, Fondateur de NOVE Engineering . /n NOVE Engineering est un projet qui se veut innovateur dans le sens où il a pour but principal de proposer des solutions d’ingénierie et optimales, basées sur les normes et recommandations professionnelles à l’échelle internationale.",
    img: image.constantpp,
  },
  {
    id: 2,
    name: "Camille FOGAING",
    role: "Directeur Marketing",
    desc: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut",
    img: image.camille,
  },
  {
    id: 3,
    name: "MEFOUNG PANGHEBEKO Carine Belvanie",
    role: "Directeur Marketing",
    desc: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut",
    img: image.mefoug,
  },
  {
    id: 4,
    name: "YONTA Maguy",
    role: "Directeur Marketing",
    desc: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut",
    img: image.yonta,
  },
  {
    id: 5,
    name: "Hippolyte KAMDEM",
    role: "Directeur Marketing",
    desc: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut",
    img: image.hypo,
  },
  {
    id: 6,
    name: "Murielle NGUESSIE",
    role: "Directeur Marketing",
    desc: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut",
    img: image.murielle,
  },
];

const valeurs = [
  {
    id: 1,
    title: "indépendance",
    desc: "Car tout ce qui est vraiment grand et inspirant est créé par l'individu qui peut travailler en toute liberté.La vie sans liberté est comme un corps sans esprit.",
    icon: image.iconFlag,
  },
  {
    id: 2,
    title: "Exigence",
    desc: "Car tout ce qui est vraiment grand et inspirant est créé par l'individu qui peut travailler en toute liberté.La vie sans liberté est comme un corps sans esprit.",
    icon: image.iconFlag,
  },
  {
    id: 3,
    title: "gestion de ses prestations",
    desc: "Car tout ce qui est vraiment grand et inspirant est créé par l'individu qui peut travailler en toute liberté.La vie sans liberté est comme un corps sans esprit.",
    icon: image.iconManagement,
  },
];

const missions = [
  { id:1 ,
    title: "Conception et Étude",
    desc: "Ajouter une texte d’un paragraphe ici qui décrit la passion evoqué par NOVE."  ,
    idLink:"/service/etudes-structures-dexecusion#conception-et-etude"
 
},
{   id : 2 ,
    title: "Audits et conseils",
    desc: "Ajouter une texte d’un paragraphe ici qui décrit la passion evoqué par NOVE." ,
    idLink:"/service/audit-et-conseils"
 
},
{   id : 3 ,
    title: "Maitrise d’ouvrage déléguée",
    desc: "Ajouter une texte d’un paragraphe ici qui décrit la passion evoqué par NOVE." ,
    idLink:"/service/management-de-projet#maitrise-d'ouvrage-deléguée"
 
},
{   id : 4 ,
    title: "Assistance à la maitrise d’ouvrage",
    desc: "Ajouter une texte d’un paragraphe ici qui décrit la passion evoqué par NOVE." ,
    idLink:"/service/management-de-projet#assistance-a-maitrise-d'ouvrage"
 
},
{   id : 5 ,
    title: "Assistance technique",
    desc: "Ajouter une texte d’un paragraphe ici qui décrit la passion evoqué par NOVE." ,
    idLink:"/service/management-de-projet#assitance-technique"
},
{
  id: 6,
  title: "Maitrise d’œuvre",
  desc: "",
  idLink: "/service/management-de-projet#assistance-a-maitrise-d'ouvrage" 
}
];

const servicesEtude = [
  {
    id: 1,
    idLink: "conception-et-etude",
    title: "Conception et Etudes",
    content:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    imgs: [
      {
        id: 1,
        src: image.imgService1,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 2,
        src: image.imgService2,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 3,
        src: image.imgService3,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
    ],
  },
  {
    id: 2,
    idLink: "etude-de-faisabilité",
    title: "Etude De Faisabilité",
    content:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    imgs: [
      {
        id: 1,
        src: image.imgService4,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 2,
        src: image.imgService5,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 3,
        src: image.imgService6,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
    ],
  },
  {
    id: 3,
    idLink: "etude-de-détails",
    title: "Etude De Détails",
    content:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    imgs: [
      {
        id: 1,
        src: image.imgService7,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 2,
        src: image.imgService8,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 3,
        src: image.imgService9,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
    ],
  },
  {
    id: 4,
    idLink: "supervision-des-travaux",
    title: "Supervision des Travaux",
    content:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    imgs: [
      {
        id: 1,
        src: image.imgService2,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 2,
        src: image.imgService5,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 3,
        src: image.imgService9,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
    ],
  },
];

const servicesManagement = [
  {
    id: 1,
    idLink: "assitance-technique",
    title: "Assistance Technique",
    content:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    imgs: [
      {
        id: 1,
        src: image.imgService1,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 2,
        src: image.imgService2,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 3,
        src: image.imgService3,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
    ],
  },
  {
    id: 2,
    idLink: "maitrise-d'ouvrage-deléguée",
    title: "Maitriese D'ouvrage Deléguée",
    content:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    imgs: [
      {
        id: 1,
        src: image.imgService4,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 2,
        src: image.imgService5,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 3,
        src: image.imgService6,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
    ],
  },
  {
    id: 3,
    idLink: "assistance-a-maitrise-d'ouvrage",
    title: "Assistance a la maitrise D'ouvrage",
    content:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    imgs: [
      {
        id: 1,
        src: image.imgService7,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 2,
        src: image.imgService8,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 3,
        src: image.imgService9,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
    ],
  },
];

const servicesAudit = [
  {
    id: 1,
    idLink: "etude-et-inspection-d'ouvrage-et-batiments",
    title: "Etude et inspection d'ouvrage et batiments",
    content:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    imgs: [
      {
        id: 1,
        src: image.imgService1,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 2,
        src: image.imgService2,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 3,
        src: image.imgService3,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
    ],
  },
  {
    id: 2,
    idLink: "propositions-des-solutions-sur-mesures-et-adaptées",
    title: "PROPOSITIONS DES SOLUTIONS SUR MESURES ET ADAPTÉES",
    content:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    imgs: [
      {
        id: 1,
        src: image.imgService4,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 2,
        src: image.imgService5,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
      {
        id: 3,
        src: image.imgService6,
        alt: "image conception et etude",
        projTitle: "Project Title",
        desc: "NOVE Engineering intervient aussi bien pour des clients privés",
      },
    ],
  },
];

const serviceLinks = [
  {
    id: 1,
    link: "conception-et-etude",
  },
  {
    id: 2,
    link: "audit-et-conseils",
  },
  {
    id: 3,
    link: "maitrise-d'ouvrage-deleguee",
  },
  {
    id: 4,
    link: "assistance-a-la-maitrise-d'ouvrage",
  },
  {
    id: 5,
    link: "assistance-technique",
  },
];

const linkEtudes = [
  {
    id: 1,
    link: "conception-et-etude",
  },
  {
    id: 2,
    link: "etude-de-faisabilité",
  },
  {
    id: 3,
    link: "etude-de-détails",
  },
  {
    id: 4,
    link: "supervision-des-travaux",
  },
];

const linkManagement = [
  {
    id: 1,
    link: "assitance-technique",
  },
  {
    id: 2,
    link: "maitrise-d'ouvrage-deléguée",
  },
  {
    id: 3,
    link: "assistance-a-maitrise-d'ouvrage",
  },
];

const linkAudit = [
  {
    id: 1,
    link: "etude-et-inspection-d'ouvrage-et-batiments",
  },
  {
    id: 2,
    link: "propositions-des-solutions-sur-mesures-et-adaptées",
  },
];

const projets = [
  {
    id: 1,
    linkId: "batiment",
    title: "Batiment",
    projList: [
      {
        id: 1,
        title: "PROJET DE CONSTRUCTION D’UN BATIMENT R+1 A USAGE D’HABITATION",
        desc: "il s’agit d’un bâtiment RDC+1 avec une emprise de 150 m²",
        img: image.imgBatiment1,
        details: [
          {
            id: 1,
            title: "client",
            name: "Un particulier",
          },
          {
            id: 2,
            title: "Emplacement",
            name: "Cameroun - Douala",
          },
          {
            id: 3,
            title: "Periode",
            name: "04 Mois",
          },
        ],
        serviceRendue: {
          serviceImage: [
            image.imgBatiment1a,
            image.imgBatiment1b,
            image.imgBatiment1c,
            image.imgBatiment1d,
          ],
        },
        name: "PROJET DE CONSTRUCTION D’UN BATIMENT R+1 A USAGE D’HABITATION",
        nameImg: image.imgBatiment1,
      },
      {
        id: 2,
        title: "PROJET DE CONSTRUCTION DE LA STATION TRADEX D’EKOREZOCK",
        desc: "Construction de la boutique et de la baie de graissage de la station-service de Tradex.",
        img: image.imgBatiment3,
        details: [
          {
            id: 1,
            title: "client",
            name: "TRADEX",
          },
          {
            id: 2,
            title: "Emplacement",
            name: "Cameroun - Yaoundé",
          },
          {
            id: 3,
            title: "Periode",
            name: "02 Ans",
          },
        ],
        serviceRendue: {
          serviceImage: [
            image.imgBatiment3a,
            image.imgBatiment3b,
            image.imgBatiment3c,
            image.imgBatiment3d,
          ],
        },
        name: "PROJET DE CONSTRUCTION DE LA STATION TRADEX D’EKOREZOCK",
        nameImg: image.imgBatiment3,
      },
      {
        id: 3,
        title: "REALISATION D’UNE ETUDE TOPOGRAPHIQUE D’UNE PARCELLE",
        desc: "Dans le cadre de la construction d’un bâtiment financé par UNOPS, un levé topographie est commandé afin de lancer la conception architecturale et structurale dudit bâtiment. ",
        img: image.imgBatiment3,
        details: [
          {
            id: 1,
            title: "client",
            name: "UNOPS",
          },
          {
            id: 2,
            title: "Emplacement",
            name: "Cameroun – Batouri",
          },
          {
            id: 3,
            title: "Periode",
            name: "Date: 2020",
          },
        ],
        serviceRendue: {
          serviceImage: [
            image.imgEnergy1a,
            image.imgEnergy1b,
            image.imgEnergy1c,
            image.imgEnergy1d,
          ],
        },
        name: "REALISATION D’UNE ETUDE TOPOGRAPHIQUE D’UNE PARCELLE",
        nameImg: image.imgBatiment3,
      },
      {
        id: 4,
        title:
          "PROJET DE REHABILITATION D’UNE VILLA MODERNE TRES HAUT STANDING",
        desc: "Il s’agit d’un bâtiment plain-pied sur une emprise d’environ 200 m².",
        img: image.imgBatiment4,
        details: [
          {
            id: 1,
            title: "client",
            name: "Un particulier",
          },
          {
            id: 2,
            title: "Emplacement",
            name: "Cameroun - Yaoundé",
          },
          {
            id: 3,
            title: "Periode",
            name: "09 Mois",
          },
        ],
        serviceRendue: {
          serviceImage: [
            image.imgEnergy1a,
            image.imgEnergy1b,
            image.imgEnergy1c,
            image.imgEnergy1d,
          ],
        },
        name: "REALISATION D’UNE ETUDE TOPOGRAPHIQUE D’UNE PARCELLE",
        nameImg: image.imgBatiment4,
      },
      {
        id: 5,
        title:
          "PROJET DE CONSTRUCTION DU SIEGE DU CENTRE FOR RESEARCH IN INFECTIOUS DISEASES-CRID",
        desc: "Il s’agit d’un Bâtiment RDC+4 abritant le siège du CRID et implanté sur une superficie de 400 m².",
        img: image.imgBatiment5,
        details: [
          {
            id: 1,
            title: "client",
            name: "CENTRE FOR RESEARCH IN INFECTIOUS DISEASES-CRID",
          },
          {
            id: 2,
            title: "Emplacement",
            name: "Cameroun – Yaoundé",
          },
          {
            id: 3,
            title: "Periode",
            name: "Date: 2021",
          },
        ],
        serviceRendue: {
          serviceImage: [
            image.imgEnergy1a,
            image.imgEnergy1b,
            image.imgEnergy1c,
            image.imgEnergy1d,
          ],
        },
        name: "PROJET DE CONSTRUCTION DU SIEGE DU CENTRE FOR RESEARCH IN INFECTIOUS DISEASES-CRID",
        nameImg: image.imgBatiment5,
      },
    ],
  },
  {
    id: 2,
    linkId: "infrastructure",
    title: "infrastructure",
    projList: [
      {
        id: "",
        title:
          "Réalisation des finitions d’un bâtiment de deux (02) étages avec sous-sol à Deido",
        desc: "msan et iusto odio dignissim qui blandit praesent",
        img: image.imgInfra1,
      },
      {
        id: "",
        title:
          "Etudes pour la construction d’un immeuble de deux (02) niveaux avec mezzanine à Bonabéri",
        desc: "e présent projet s’inscrit dans le cadre de la dynamique de l’amélioration et de la consolidation de la structure de logement de la ville de Douala.",
        img: image.imgInfra2,
      },
      {
        id: "",
        title:
          "Etudes pour la construction d’un immeuble de quatre (04) niveaux à Bonapriso 1",
        desc: "Notre bâtiment sera conçu selon les normes d’une architecture contemporaine, avec un équilibre entre la conception et la construction de l’habitat, son milieu.",
        img: image.imgInfra3,
      },
      {
        id: "",
        title:
          "Etudes pour la construction d’un immeuble de deux (02) niveaux à Yatchika",
        desc: "Le présent projet s’inscrit dans le cadre de la dynamique de l’amélioration et de la consolidation de la structure de logement de la ville de Douala.",
        img: image.imgInfra4,
      },
      {
        id: "",
        title:
          "Synthèse de l’Etude de positionnement stratégique relative à la branche Poissons au Cameroun",
        desc: "L’étude de positionnement stratégique permet de dresser un diagnostic de la situation globale des entreprises de la branche d’activité étudiée. ",
        img: image.imgInfra5,
      },
      {
        id: "",
        title:
          "Projet de construction & de rénovation / réhabilitation – MOULONGO",
        desc: "Concernant le premier ouvrage, il s’agit d’une construction d’un immeuble R+2. Il comportera un rez-de-chaussée réservé aux parkings et les deux (02) étages prévus pour les appartements de type T4.",
        img: image.imgInfra6,
      },
    ],
  },
  {
    id: 3,
    linkId: "ouvrages d’art",
    title: "Ouvrages d’art",
    projList: [
      {
        id: "",
        title:
          "Etudes pour la construction d’un immeuble de deux (02) niveaux à Yatchika",
        desc: "msan et iusto odio dignissim qui blandit praesent",
        img: image.imgInfra1,
      },
      {
        id: "",
        title:
          "Projet de construction & de rénovation / réhabilitation – MOULONGO",
        desc: "msan et iusto odio dignissim qui blandit praesent",
        img: image.imgInfra2,
      },
      {
        id: "",
        title:
          "Réalisation des finitions d’un bâtiment de deux (02) étages avec sous-sol à Deido",
        desc: "msan et iusto odio dignissim qui blandit praesent",
        img: image.imgInfra3,
      },
      {
        id: "",
        title:
          "Etudes pour la construction d’un immeuble de quatre (04) niveaux à Bonapriso 1",
        desc: "msan et iusto odio dignissim qui blandit praesent",
        img: image.imgInfra5,
      },
      {
        id: "",
        title:
          "Réalisation des finitions d’un bâtiment de deux (02) étages avec sous-sol à Deido",
        desc: "msan et iusto odio dignissim qui blandit praesent",
        img: image.imgInfra6,
      },
    ],
  },
  {
    id: 4,
    linkId: "energie",
    title: "Energie",
    projList: [
      {
        id: 12,
        title:
          "PROJET D’ALIMENTATION EN EAU POTABLE DUPORT AUTONOME DE KRIBI ET SES DEPENDANCES RESERVOIR DE REPRISE DE 1500 M3",
        desc: "Le réservoir de reprise de 1500 m3 est un ouvrage de retenue d’un diamètre intérieur de 25.0 m et d’une hauteur d’environ 5.0 m. ",
        img: image.imgEnergy1,
        details: [
          {
            id: 1,
            title: "client",
            name: "Port Autonome de Kribi (PAK)",
          },
          {
            id: 2,
            title: "Emplacement",
            name: "Cameroun - KRIBI",
          },
          {
            id: 3,
            title: "Periode",
            name: `${computeProjectDuration(2019, 2020)} Years`,
          },
        ],
        serviceRendue: {
          serviceImage: [
            image.imgEnergy1a,
            image.imgEnergy1b,
            image.imgEnergy1c,
            image.imgEnergy1d,
          ],
        },
        name: "PROJET D’ALIMENTATION EN EAU POTABLE DUPORT AUTONOME DE KRIBI ET SES DEPENDANCES RESERVOIR DE REPRISE DE 1500 M3",
        nameImg: image.imgEnergy1,
      },
      {
        id: 13,
        title:
          "PROJET D’ALIMENTATION EN EAU POTABLE DU PORT AUTONOME DE KRIBI ET SES DEPENDANCES : CASCADE AERATEUR, LE FLOCULATEUR ET LE DECANTEUR",
        desc: "Il s’agit d’un ensemble de trois ouvrages en séries constitués d’un ensemble des structures en béton armé et métalliques.",
        img: image.imgEnergy2,
        details: [
          {
            id: 1,
            title: "client",
            name: "Port Autonome de Kribi (PAK)",
          },
          {
            id: 2,
            title: "Emplacement",
            name: "Cameroun - KRIBI",
          },
          {
            id: 3,
            title: "Periode",
            name: `${computeProjectDuration(2019, 2020)} Years`,
          },
        ],
        serviceRendue: {
          serviceImage: [
            image.imgEnergy2a,
            image.imgEnergy2b,
            image.imgEnergy2c,
            image.imgEnergy2d,
            image.imgEnergy2e,
          ],
        },
        name: "PROJET D’ALIMENTATION EN EAU POTABLE DU PORT AUTONOME DE KRIBI ET SES DEPENDANCES : CASCADE AERATEUR, LE FLOCULATEUR ET LE DECANTEUR",
        nameImg: image.imgEnergy2,
      },
      {
        id: 14,
        title:
          "PROJET D’ALIMENTATION EN EAU POTABLE DU PORT AUTONOME DE KRIBI ET SES DEPENDANCES : LE DESSABLEUR",
        desc: "Le Dessableur est un ouvrage hydraulique principalement en béton armé et symétrie dans sa géométrie",
        img: image.imgEnergy3,
        details: [
          {
            id: 1,
            title: "client",
            name: "Port Autonome de Kribi (PAK)",
          },
          {
            id: 2,
            title: "Emplacement",
            name: "Cameroun - KRIBI",
          },
          {
            id: 3,
            title: "Periode",
            name: `${computeProjectDuration(2019, 2020)} Years`,
          },
        ],
        serviceRendue: {
          serviceImage: [image.imgEnergy3a],
        },
        name: "PROJET D’ALIMENTATION EN EAU POTABLE DU PORT AUTONOME DE KRIBI ET SES DEPENDANCES : LE DESSABLEUR",
        nameImg: image.imgEnergy3,
      },
    ],
  },
];

const favProjets = [
  {
    id: 4,
    name: "PROJET DE CONSTRUCTION D’UN BATIMENT R+1 A USAGE D’HABITATION",
    desc: "il s’agit d’un bâtiment RDC+1 avec une emprise de 150 m²",
    categorie: "Bâtiment à usage commercial",
    img: image.imgBatiment2,
  },
  {
    id: 2,
    name: "PROJET DE CONSTRUCTION DE LA STATION TRADEX D’EKOREZOCK",
    desc: "Construction de la boutique et de la baie de graissage de la station-service de Tradex.",
    categorie: "Bâtiment",
    img: image.imgBatiment3,
  },
];

const tabCategory = [
  {
    id: 1,
    name: "administratifs",
  },
  {
    id: 2,
    name: "culturels et sportifs",
  },
  {
    id: 3,
    name: "industriels",
  },
  {
    id: 4,
    name: "résidentiels",
  },
  {
    id: 5,
    name: "scolaires",
  },
];

const domainesLinks = [
  {
    id: 1,
    link: "batiment",
  },
  {
    id: 2,
    link: "infrastructure",
  },
  {
    id: 3,
    link: "ouvrages d’art",
  },
  {
    id: 4,
    link: "hydrolique",
  },
];

const similarProjet = [
  {
    id: 2,
    img: image.imgBatiment2,
    title: "PROJET DE CONSTRUCTION DE LA STATION TRADEX D’EKOREZOCK",
    desc: ": Construction de la boutique et de la baie de graissage de la station-service de Tradex.",
  },
  {
    id: 3,
    img: image.imgBatiment3,
    title: "PROJET DE CONSTRUCTION D’UN BATIMENT RDC +2 A USAGE D’HABITATION ",
    desc: "io dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
  },
  {
    id: 4,
    img: image.imgBatiment4,
    title: "REALISATION D’UNE ETUDE TOPOGRAPHIQUE D’UNE PARCEL",
    desc: "io dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
  },
];

const projList = [
  {
    id: 1,
    projTitle: "Nom du projet",
    desc: "NOVE Engineering intervient aussi bien pour des clients privés",
    src: image.imgBatiment1,
  },
  {
    id: 2,
    projTitle: "Nom du projet",
    desc: "NOVE Engineering intervient aussi bien pour des clients privés",
    src: image.imgBatiment3,
  },
  {
    id: 3,
    projTitle: "Nom du projet",
    desc: "NOVE Engineering intervient aussi bien pour des clients privés",
    src: image.imgInfra6,
  },
  {
    id: 4,
    projTitle: "Nom du projet",
    desc: "NOVE Engineering intervient aussi bien pour des clients privés",
    src: image.imgBatiment4,
  },
  {
    id: 5,
    projTitle: "Nom du projet",
    desc: "NOVE Engineering intervient aussi bien pour des clients privés",
    src: image.imgInfra6,
  },
  {
    id: 6,
    projTitle: "Nom du projet",
    desc: "NOVE Engineering intervient aussi bien pour des clients privés",
    src: image.imgInfra2,
  },
];

export default {
  navData,
  inputs,
  teamMembers,
  valeurs,
  missions,
  servicesEtude,
  servicesManagement,
  servicesAudit,
  linkAudit,
  linkEtudes,
  linkManagement,
  projets,
  favProjets,
  tabCategory,
  domainesLinks,
  similarProjet,
  projList,
  subLink,
  domainLink,
};
