import React, { useState } from "react";

import { NavLink, Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { data } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const MobileDropDownLinkItem = ({
  path,
  textLink,
  hasActive,
  setActive,
  setOpen,
  isOpened,
  setIsOpen,
}) => {
  const handleHasActive = () => {
    if (hasActive === true) {
      setActive(false);
    } else setActive(true);
  };
  const {t} = useTranslation("common");

  const handleStateChange = () => {
    setOpen(!isOpened);
    setIsOpen(false);
  };
  return (
    <li className="link-dropdown flex justify-center items-center flex-col px-3">
      <a
        href={path}
        onClick={() => handleStateChange()}
        className={
          isOpened ? "link-item active font-bold" : "link-item justify-center"
        }
      >
        <span className="flex items-center ">
          <span className="block">{textLink}</span>
          <IoIosArrowDown
            onClick={() => setOpen(true)}
            className={
              isOpened
                ? "rotate-180 transition-all"
                : "rotate-0 text-blue-500 font-bold block ml-2"
            }
          />
        </span>
      </a>
      <ul className={isOpened ? "active mt-2 rounded-sm" : ""}>
        {data.subLink.map((item, index) => (
          <li key={item.id} className="item-select">
            <NavLink
              to={`/${'service/'}${item.path}`}
              className="text-[12px]"
              onClick={(e) => {
                handleHasActive();
                setOpen(false);

    }}
            >
              {t(`nosServices.${index}`)}
            </NavLink>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default MobileDropDownLinkItem;
