import React, { Fragment, useLayoutEffect } from 'react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

import { TabButton } from '../../components';
import { data } from '../../utils/constants';
import projectData from '../../utils/constants/projectData';
import DomaineNav from './DomaineNav';
import DomainNavResponsive from './DomainNavResponsive';
import { useRef } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const DomaineContent = ({ list, alt }) => {
    
    const { t } = useTranslation("domaines");
    const ref = useRef(null);
    let buttonCount = 4;
    
    const [height, setHeight] = useState(null);

    useLayoutEffect(() => {
        setHeight(ref.current.clientHeight);
    }, []);

    const settings = {
        infinite: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 3000,
    }

  return (
    <Fragment>
        <section className=" py-[90px] lg:py-[115px]" ref={ref}>
            <div className="wrapper 2xl:flex relative">
                <div className="w-full max-w-[885px] mx-auto lg:translate-x-0 2xl:-translate-x-[0px] px-[2rem] sm:first-letter:px-[4rem] lg:px-0">
                    <div className="tab-container lg:flex lg:justify-start lg:items-center gap-x-[20px]">
                        {
                                data.tabCategory.slice(0, buttonCount).map((tab, index) => {
                                return(
                                    <Fragment key={tab.id}><TabButton button={t(`domainFilterButtonEnergy.${index}`)} value={tab.name}/></Fragment>
                                )
                            })
                        }
                    </div>

                    <div className="filtered-content mt-[107px] max-w-[885px] mx-auto my-0">
                        <h2 className="headline-border">{t('domainAssistanceMaitrise.assistanceMaitrise')}</h2>
                        <p className="my-[70px]">{t('domainAssistanceMaitrise.assistanceMaitriseDesc')}</p>
                        

                        <div className="img-filtered lg:grid lg:grid-cols-3 gap-x-8 gap-y-8 max-w-[885px] mx-auto hidden">
                            {
                                            projectData.projects.map((item, index) => (
                                                (item.domaineId === 4 ) ?
                                                <Link to={`/nos-projets/${item.id}`}>
                                                    <div className="img-card rounded-sm">
                                                        <img src={item.imgs[0]} alt={alt} className="w-full h-full object-cover" />
                                                        <div className="desc">
                                                            <h6 className="text-md text-blue-100 text-ellipsis">{item.name} </h6>
                                                        </div>
                                                    </div>
                                                </Link>: <></>
                                            ))
                            }
                        </div>

                        <div className="img-filtered md:max-w-[400px] md:mx-auto lg:hidden">
                            <Slider {...settings}>
                                {
                                    projectData.projects.map((item, index) => (
                                        (item.domaineId === 4 ) ?
                                        <Link to={`/nos-projets/${item.id}`}>
                                            <div className="img-card rounded-sm">
                                                <img src={item.imgs[0]} alt={alt} className="w-full h-full object-cover" />
                                                <div className="desc">
                                                    <h6 className="text-md text-blue-100 text-ellipsis">{item.name} </h6>
                                                </div>
                                            </div>
                                        </Link>: <></>
                                    ))
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
  )
}

export default DomaineContent