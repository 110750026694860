import React, { Fragment, useContext } from "react";
import { useState } from "react";
import { image } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { DomaineContext } from "../projets/Projet";



const ServiceRendue = ({ project }) => {

  const domaines = useContext(DomaineContext) ;
  const domaine = domaines[project.domaineId-1] ;

  const {t} = useTranslation("projets") ;
  return (
    <Fragment>
      <section className="service-rendue py-[90px] lg:py-[115px]">
        <div className="content-wrapper">
          <h2 className="headline-border">{project.secteurDactivite}</h2>

          <div className="mt-[20px]"> {project.description}</div>

          <div className="service-container mt-[40px] lg:mt-[115px] max-w-[969px] mx-auto relative h-[500px] lg:h-[762px]">
            <div className="service-img absolute top-0 left-0 w-[626px] h-[546px] hidden lg:block shadow-2xl">
              <img
              src={project.imgs[0]}
                alt="img service rendue"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="service-description absolute bottom-0 w-[100%] right-0 lg:w-[543px] h-[auto] lg:h-[546px] py-[100px] lg:py-[100px] lg:px-[77px] px-[30px] shadow-lg bg-white">
              <h3 className="heading text-[32px] lg:text-[48px] font-light">
                {t("serviceRendu")}
              </h3>
              <ul className="service-list list-disc mt-[41px] space-y-8">
                {project.servicesRendus.map((item, index) => (
                  <>
                    <li index={index}>{item}</li>
                  </>
                ))}
              </ul>
              <span className="line block h-1 absolute bottom-0 right-0 bg-blue-500  w-[70%]"></span>
            </div>
          </div>

        </div>
      </section>
    </Fragment>
  );
};

export default ServiceRendue;
