import React, {useEffect , Fragment } from 'react';
import ReactGA from "react-ga"

import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { Header } from '../../components';
//import AnimatePage from '../../components/UI/AnimatePage';
import SEO from '../../SEO';
import { data, image } from '../../utils/constants';
import DomaineContent from './DomaineContent';

const Domaines = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const { t } = useTranslation("domaines");
  return (
    <Fragment>
      <SEO
        title="domaines nove"
        description="NOVE et la prestation en ces domaines"
        type="website"
        img="https://img.freepik.com/premium-photo/engineer-black-women-worker-professional-woman-afican-mechanical-maintenance-work-factory_43300-3538.jpg?w=360"
      />

      <Header
        img={image.imgProj2}
        title={t('domainHeadline')}
        suptitle={t('suptitle')}
      />

      <Outlet/>
    </Fragment>
  )
}

export default Domaines
