import React, { Fragment, useEffect } from 'react'
import ReactGA from 'react-ga' ;


import Aos from 'aos';
import 'aos/dist/aos.css';

import { image } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation("about-us");
    useEffect(() => {
        Aos.init({once: true});
    })
  return (
    <Fragment>
         <section className="nove-intro py-[90px] md:py-[96px] lg:py-[109px] bg-[#F9F9F9]">
            <div className="content-wrapper">
                <h2 className="headline-border uppercase mb-[40px]">{t('aboutHeading')}</h2>

                <div className="row-intro flex flex-col md:flex-row md:min-h-full md:w-full lg:grid lg:grid-cols-2 md:gap-x-[30px] lg:gap-[50px] xl:gap-[96px] lg:mt-[90px] xl:mt-[109px]">
                    <div className="col-2 md:w-2/3 lg:w-full md:flex lg:block">
                        <div className="text-black-500 col-2-text md:text-justify">
                            <p className="mt-6">{t('aboutDescSection')}</p>
                            <p className="mt-6">{t('aboutDescription2')}</p>
                            <p className="mt-6">{t('aboutDescription3')}</p>
                            <p className="mt-6">{t('aboutDescription4')}</p>
                        </div>
                    </div>
                    <div className="col-2 mt-[40px] md:mt-0 lg:h-[532px] xl:h-[632px] lg:mt-0 md:w-1/3 lg:w-full">
                        <div className="img-dispo h-full">
                            <div className="desktop hidden lg:block relative lg:h-[532px] xl:h-[632px]">
                                <img 
                                src={image.introImage1} 
                                alt="nove team holding hands"  
                                className='absolute top-0 right-0 lg:w-[300px] xl:w-[456.91px] ' 
                                data-aos="zoom-in-down"
                                data-aos-easing="linear"
                                data-aos-delay="300"
                                />
                                <img 
                                src={image.introImage2} 
                                alt="nove engineer" 
                                className='absolute bottom-0 left-0 lg:w-[300px] xl:w-[456.91px]'
                                data-aos="zoom-in-up"
                                data-aos-easing="linear"
                                data-aos-delay="300" 
                                />
                            </div>
                            <div className="mobile lg:hidden flex justify-center h-full w-full">
                                <img src={image.introImage2} alt="nove engineer" className="object-cover object-right"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
  )
}

export default About
