import React from 'react'
import { Outlet } from 'react-router-dom'
import { Banner, Footer, Navbar } from '../components'

const LayoutTemplate = () => {
  return (
    <>
        <Outlet />
        <Banner />
        <Footer />
    </>
  )
}

export default LayoutTemplate